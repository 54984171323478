import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';
import { Category, PropertyStatus, PropertyTypeEnum, REAGetOfferResponse, REAGetPropertiesResponseWithIndex, REAGetPropertyDetailsResponse } from '@dm/types';

export interface GetPropertyListingParams {
    authToken: string;
    name: string;
    type: PropertyTypeEnum;
    status: PropertyStatus;
    index: number;
}

export interface GetPropertyDetailsParams {
    authToken: string;
    id: string;
}

export interface GetCoBrokeUploadUrlParams {
    authToken: string;
    category: Category;
    id: string;
    name: string;
    extension: string;
}

export interface UploadCoBrokeDocumentParams {
    url: string;
    doc: FormData;
}

export interface GetCoBrokeDocumentTemplateParams {
    authToken: string;
    documentTemplate: string,
    propertyId: string
}

export interface GetViewOfferSessionsParams {
    authToken: string;
    propertyId: string
}

export interface setSubmitOffersParams {
    authToken: string;
    offerSessionId: string,
    price: number
}
export interface requestKeyDateParams {
    authToken: string;
    propertyId: string;
    requestKeyDate: string;
}

export abstract class IPropertyGateway extends Gateway {
    abstract getPropertyListing(params: GetPropertyListingParams): GatewayResponse<REAGetPropertiesResponseWithIndex>;

    abstract getPropertyDetails(params: GetPropertyDetailsParams): GatewayResponse<REAGetPropertyDetailsResponse>;

    abstract getCoBrokeUploadUrl(params: GetCoBrokeUploadUrlParams): GatewayResponse<string>;

    abstract uploadCoBrokeDocument(params: UploadCoBrokeDocumentParams): GatewayResponse<null>;

    abstract getCoBrokeDocumentTemplate(params: GetCoBrokeDocumentTemplateParams): GatewayResponse<string>;

    abstract getPropertyViewOfferSessions(params: GetViewOfferSessionsParams): GatewayResponse<REAGetOfferResponse[] | null>;

    abstract setPropertySubmitOffers(params: setSubmitOffersParams): GatewayResponse<string>;

    abstract setRequestPropertyKey(params: requestKeyDateParams): GatewayResponse<string>;
}
