import React, { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';

import { FatButton, EventTrail } from '@dm/bigfish';

import NavActions from 'lib/NavActions';
import Translate from 'lib/translate';

const Modules: FunctionComponent = () => {
    const [pendingActivities, setPendingActivities] = useState([]);
    const [pendingApprovals, setPendingApprovals] = useState([]);

    return (
        <Container>
            <SelectionContainer>
                <FatButton
                    title={Translate.t('Modules.ModulesSearch')}
                    subtitle={Translate.t('Modules.ModulesSearchDesc')}
                    onClick={() => NavActions.navToPropertyList()}
                />
                {/* TEMP */}
                {/* <FatButton
                    title={Translate.t('Modules.ModulesReports')}
                    subtitle={Translate.t('Modules.ModulesReportsDesc')}
                    onClick={() => { return false; }}
                />
                <FatButton
                    title={Translate.t('Modules.ModulesCurrentProperties')}
                    subtitle={Translate.t('Modules.ModulesCurrentPropertiesDesc')}
                    onClick={() => { return false; }}
                />
                <FatButton
                    title={Translate.t('Modules.ModulesTenants')}
                    subtitle={Translate.t('Modules.ModulesTenantsDesc')}
                    onClick={() => { return false; }}
                />
                <FatButton
                    title={Translate.t('Modules.ModulesBids')}
                    subtitle={Translate.t('Modules.ModulesBidsDesc')}
                    onClick={() => { return false; }}
                /> */}
                <FatButton
                    title={Translate.t('Modules.ModulesCoBrooking')}
                    subtitle={Translate.t('Modules.ModulesCoBrookingDesc')}
                    onClick={() => { return false; }}
                />
            </SelectionContainer>

            {/* TEMP */}
            {/* <EventsContainer>
                <EventTrail
                    primary
                    title={Translate.t('Modules.ModulesPendingActivities')}
                    buttonLabel={Translate.t('Modules.ModulesPendingActivitiesButton')}
                    emptyText={Translate.t('Modules.ModulesPendingActivitiesEmpty')}
                    data={pendingActivities}
                    onClickButtonLabel={() => { return false; }}
                />
                <EventTrail
                    title={Translate.t('Modules.ModulesPendingApprovals')}
                    buttonLabel={Translate.t('Modules.ModulesPendingApprovalsButton')}
                    emptyText={Translate.t('Modules.ModulesPendingApprovalsEmpty')}
                    data={pendingApprovals}
                    onClickButtonLabel={() => { return false; }}
                />
            </EventsContainer> */}
        </Container>
    );
};

const Container = styled.div`
    display: flex;

    /* TEMP */
    /* justify-content: center; */
`;

const SelectionContainer = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(304px, 1fr) );
    gap: 20px;
    width: 70%;
    margin-right: 16px;

    /* TEMP */
    /* width: 100%; */
`;

const EventsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 544px;

    > div:not(:last-child){
        margin-bottom: 16px;
    }
`;

export default Modules;
