import React, { FunctionComponent, useState, useEffect, useRef, SyntheticEvent } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import { toast } from 'react-toastify';
import { Oval } from 'react-loader-spinner';

import { Colors, RoundInput, Button } from '@dm/bigfish';
import { Category, IREAUser } from '@dm/types';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import Translate from 'lib/translate';

import icons from 'assets/icons';

import Modal from 'components/Modal';

interface CoBrokeDocumentModalProps {
    userInfo: IREAUser | null;

    isModalOpen: boolean;
    getUrlLoading: boolean;
    uploadCoBrokeDocumentLoading: boolean;

    getUrlError: string;
    uploadCoBrokeDocumentSuccess: string;
    selectedPropertyId: string;

    getUploadUrl: (category: Category, id: string, name: string, extension: string, document: File) => void;
    setUploadCoBrokeDocumentModalOpen: (state: boolean) => void;
}

const CoBrokeDocumentModal: FunctionComponent<CoBrokeDocumentModalProps> = (props: CoBrokeDocumentModalProps) => {
    const {
        userInfo,
        isModalOpen,
        getUrlLoading,
        uploadCoBrokeDocumentLoading,
        getUrlError,
        uploadCoBrokeDocumentSuccess,
        selectedPropertyId,
        getUploadUrl,
        setUploadCoBrokeDocumentModalOpen,
    } = props;

    const [stateUserId, setStateUserId] = useState('');

    useEffect(() => {
        if (userInfo) {
            const { userId } = userInfo;
            setStateUserId(userId);
        }
    }, [userInfo]);

    useEffect(() => {
        if (uploadCoBrokeDocumentSuccess !== '') {
            setUploadCoBrokeDocumentModalOpen(!isModalOpen);
        }
    }, [uploadCoBrokeDocumentSuccess]);

    const fileInput = useRef<HTMLInputElement>(null);

    const onClickHandler = () => {
        if (fileInput.current) {
            fileInput.current.value = '';
            fileInput.current.click();
        }
    };

    const fileSelectHandler = async (event: SyntheticEvent) => {
        const target = event.target as HTMLInputElement;

        let documentExtension = '';

        if (target.files) {
            const doc = target.files[0];

            const { type } = doc;

            switch (type) {
                case 'application/pdf': documentExtension = '.pdf'; break;
                case 'image/jpg': documentExtension = '.jpg'; break;
                case 'image/jpeg': documentExtension = '.jpeg'; break;
                case 'image/png': documentExtension = '.png'; break;
                default:
            }

            if ((documentExtension === ('.png')) || (documentExtension === ('.jpeg')) || (documentExtension === ('.jpg')) || (documentExtension === ('.pdf'))) {
                getUploadUrl(Category.CoBroke, stateUserId, selectedPropertyId, documentExtension, doc);
            } else {
                toast.error(Translate.t('Search.Filetypenotsupported'));
            }
        }
    };

    const closeModal = () => {
        setUploadCoBrokeDocumentModalOpen(!isModalOpen);
    };

    const renderModalBody = () => {
        if (getUrlLoading || uploadCoBrokeDocumentLoading) {
            return (
                <LoadingContainer>
                    <Oval
                        height={150}
                        width={150}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </LoadingContainer>
            );
        }

        return (
            <ContentContainer>
                <div>
                    <InputItemContainer>
                        <input
                            type='file'
                            onChange={(e: SyntheticEvent) => fileSelectHandler(e)}
                            ref={fileInput}
                            accept='application/pdf, .jpg, .png, .jpeg'
                            style={{
                                display: 'none',
                            }}
                        />

                        <UploadButton
                            onClick={onClickHandler}
                        >
                            <UploadIcon
                                src={icons.UploadIcon}
                            />

                            <UploadDocumentText>
                                {Translate.t('SignUp.SignUpRENModalUploadButtonLabel')}
                            </UploadDocumentText>
                        </UploadButton>

                    </InputItemContainer>

                    <NoteText>
                        {Translate.t('SignUp.SignUpRENModalUploadDisclaimer')}
                    </NoteText>
                </div>
            </ContentContainer>
        );
    };

    return (
        <Modal
            show={isModalOpen}
            width='30%'
        >
            <HeaderContainer>
                <Title>
                    {Translate.t('Search.CoBrokeModalTitle')}
                </Title>
                <UploadCloseButton onClick={closeModal} label={Translate.t('Search.SearchButtonClose')} />
            </HeaderContainer>

            {renderModalBody()}
        </Modal>

    );
};

const NoteText = styled.p`
    font-size: 16px;
`;

const UploadDocumentText = styled.p`
    margin-left: 20px;
`;

const ContentContainer = styled.div`
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const UploadButton = styled.button`
    width: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: white;
`;

const UploadIcon = styled(SVG)`
`;

const UploadCloseButton = styled(Button)`
    max-height: 30px;
    padding: 8px;
    line-height: 3px;
`;

const InputItemContainer = styled.div`
    display: flex;
    align-items: center;
    max-width: 160px;
    border-radius: 8px;
    margin-bottom: 8px;
    background-color: #F2B47E;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: white;
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Title = styled.h1`
    font-size: 20px;
    margin-bottom: 20px;
`;

const mapStateToProps = (state: RootState) => ({
    isModalOpen: Selectors.propertySetIsCoBrokeUploadModalOpen(state),
    userInfo: Selectors.userGetUserInfo(state),
    getUrlLoading: Selectors.propertyGetCoBrokeUploadUrlAttempting(state),
    getUrlError: Selectors.propertyGetCoBrokeUploadUrlError(state),
    uploadCoBrokeDocumentLoading: Selectors.propertyUploadCoBrokeDocumentAttempting(state),
    uploadCoBrokeDocumentSuccess: Selectors.propertyUploadCoBrokeDocument(state),
    selectedPropertyId: Selectors.propertyGetSelectedPropertyId(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getUploadUrl: (category: Category, id: string, name: string, extension: string, document: File) => dispatch(Actions.getCoBrokeUploadUrlAttempt({ category, id, name, extension, document })),
    setUploadCoBrokeDocumentModalOpen: (state: boolean) => dispatch(Actions.setCoBrokeUploadModalOpen(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoBrokeDocumentModal);
