import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { RootState, AppDispatch } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { BreadCrumb, TabView } from '@dm/bigfish';

import Translate from 'lib/translate';
import NavActions from 'lib/NavActions';

import MainContainer from 'components/MainContainer';

import Modules from './components/Modules';
import Stats from './components/Stats';

interface HomeScreenProps {
    userInfoName: string;
    setTabView: (tabView: string) => void;
}

const HomeScreen = (props: HomeScreenProps) : JSX.Element => {
    const { userInfoName, setTabView } = props;

    const [selectedTab, setSelectedTab] = useState('modules');
    const [breadCrumb, setBreadCrumb] = useState(
        [
            {
                label: Translate.t('BreadCrumb.BreadCrumbDashboard'),
                onClick: () => { return false; },
            },
            {
                label: `${Translate.t('BreadCrumb.BreadCrumbWelcomeBack')} Agent!`,
                onClick: () => { return false; },
            },
        ],
    );

    const [tabViewData] = useState([
        // TEMP
        // {
        //     text: Translate.t('Modules.ModulesStats'),
        //     onClickTabView: () => {
        //         setSelectedTab('stats');
        //     },
        // },
        {
            text: Translate.t('Modules.ModulesModules'),
            onClickTabView: () => {
                setSelectedTab('modules');
            },
        },
    ]);

    useEffect(() => {
        setTabView('dashboard');
    }, []);

    useEffect(() => {
        setBreadCrumb([
            {
                label: Translate.t('BreadCrumb.BreadCrumbDashboard'),
                onClick: () => { return false; },
            },
            {
                label: `${Translate.t('BreadCrumb.BreadCrumbWelcomeBack')} ${userInfoName}!`,
                onClick: () => { return false; },
            },
        ]);
    }, [userInfoName]);

    const renderHomeScreenContent = () => {
        if (selectedTab === 'stats') {
            return (
                <Stats />
            );
        }

        return (
            <Modules />
        );
    };

    return (
        <>
            <BreadCrumb
                onBackPressed={() => NavActions.navBack()}
                data={breadCrumb}
            />
            <MainContainer>
                <TabViewContainer>
                    <TabView
                        data={tabViewData}
                        selectedTabView={selectedTab}
                    />
                </TabViewContainer>
                <HomeScreenContentContainer>
                    {renderHomeScreenContent()}
                </HomeScreenContentContainer>
            </MainContainer>
        </>
    );
};

const TabViewContainer = styled.div`
    margin-top: 30px;
    margin-left: 20px;
`;

const HomeScreenContentContainer = styled.div`
    padding: 40px 20px;
`;

const mapStateToProps = (state: RootState) => ({
    userInfoName: Selectors.userGetUserInfoName(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setTabView: (tabView: string) =>
        dispatch(Actions.setTabView(tabView)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
