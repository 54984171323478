import { IREAUser, REAGetOfferResponse, REAGetPropertiesResponseWithIndex, REAGetPropertyDetailsResponse } from '@dm/types';
import auth from 'redux/slices/auth/Selectors';
import ui from 'redux/slices/ui/selectors';
import user from 'redux/slices/user/selectors';
import property from 'redux/slices/property/selectors';

import { RootState } from './store';

// Auth
const authGetStartupAttempting = (state: RootState): boolean => auth.getStartupAttempting(state.auth);
const authGetStartupError = (state: RootState): string => auth.getStartupError(state.auth);

const authGetLoginAttempting = (state: RootState): boolean => auth.getLoginAttempting(state.auth);
const authGetLoginError = (state: RootState): string => auth.getLoginError(state.auth);

const authGetAuthToken = (state: RootState): string => auth.getAuthToken(state.auth);

const authGetSignupAttempting = (state: RootState): boolean => auth.getSignupAttempting(state.auth);
const authGetSignupError = (state: RootState): string => auth.getSignupError(state.auth);

const authGetVerifySignUpAttempt = (state: RootState): boolean => auth.getVerifySignUpAttempt(state.auth);
const authGetVerifySignUpError = (state: RootState): string => auth.getVerifySignUpError(state.auth);
const authGetVerifySignUp = (state: RootState): boolean => auth.getVerifySignUp(state.auth);

const authGetUnsubscribeAttempt = (state: RootState): boolean => auth.getUnsubscribeAttempt(state.auth);
const authGetUnsubscribeError = (state: RootState): string => auth.getUnsubscribeError(state.auth);

// UI
const getUiSelectedLanguage = (state: RootState): string => ui.getSelectedLanguage(state.ui);
const getUiSelectedTabView = (state: RootState): string => ui.getSelectedTabView(state.ui);
const getUiModulesCreateModal = (state: RootState): boolean => ui.getModulesCreateModal(state.ui);
const getUiCurrentPathName = (state: RootState): string => ui.getCurrentPathName(state.ui);

// User
const userGetUserInfoAttempting = (state: RootState): boolean => user.getUserInfoAttempting(state.user);
const userGetUserInfoError = (state: RootState): string => user.getUserInfoError(state.user);

const userGetUserInfo = (state: RootState): IREAUser | null => user.getUserInfo(state.user);

const userGetUserInfoName = (state: RootState): string => user.getUserInfoName(state.user);

const userUpdateUserInfoAttempting = (state: RootState): boolean => user.updateUserInfoAttempting(state.user);
const userUpdateUserInfoError = (state: RootState): string => user.updateUserInfoError(state.user);

const userGetIsRENUploadModalOpen = (state: RootState): boolean => user.getIsRENUploadModalOpen(state.user);

const userGetRENUploadUrlAttempting = (state: RootState): boolean => user.getREAUploadUrlAttempting(state.user);
const userGetRENUploadUrlError = (state: RootState): string => user.getREAUploadUrlError(state.user);

const userGetRENUploadUrl = (state: RootState): string => user.getREAUploadUrl(state.user);

const userUploadRENDocumentAttempting = (state: RootState): boolean => user.uploadRENDocumentAttempting(state.user);
const userUploadRENDocumentError = (state: RootState): string => user.uploadRENDocumentError(state.user);

// Property
const propertyGetPropertyListingAttempting = (state: RootState): boolean => property.getPropertyListingAttempting(state.property);
const propertyGetPropertyListingError = (state: RootState): string => property.getPropertyListingError(state.property);

const propertyGetPropertyListing = (state: RootState): REAGetPropertiesResponseWithIndex => property.getPropertyListing(state.property);

const propertyGetPropertyDetailsAttempting = (state: RootState): boolean => property.getPropertyDetailsAttempting(state.property);
const propertyGetPropertyDetailsError = (state: RootState): string => property.getPropertyDetailsError(state.property);

const propertyGetPropertyDetails = (state: RootState): REAGetPropertyDetailsResponse => property.getPropertyDetails(state.property);

const propertyGetCoBrokeUploadUrlAttempting = (state: RootState): boolean => property.getCoBrokeUploadUrlAttempting(state.property);
const propertyGetCoBrokeUploadUrlError = (state: RootState): string => property.getCoBrokeUploadUrlError(state.property);

const propertyGetCoBrokeUploadUrl = (state: RootState): string => property.getCoBrokeUploadUrl(state.property);

const propertyUploadCoBrokeDocumentAttempting = (state: RootState): boolean => property.uploadCoBrokeDocumentAttempting(state.property);
const propertyUploadCoBrokeDocumentError = (state: RootState): string => property.uploadCoBrokeDocumentError(state.property);
const propertyUploadCoBrokeDocument = (state: RootState): string => property.uploadCoBrokeDocument(state.property);

const propertyGetPropertyCoBrokeDocumentTemplateAttempting = (state: RootState): boolean => property.getPropertyCoBrokeDocumentTemplateAttempting(state.property);
const propertyGetPropertyCoBrokeDocumentTemplateError = (state: RootState): string => property.getPropertyCoBrokeDocumentTemplateError(state.property);
const propertyGetPropertyCoBrokeDocumentTemplate = (state: RootState): string => property.getPropertyCoBrokeDocumentTemplate(state.property);

const propertySetIsCoBrokeUploadModalOpen = (state: RootState): boolean => property.setIsCoBrokeUploadModalOpen(state.property);

const propertyGetSelectedPropertyId = (state: RootState): string => property.getSelectedPropertyId(state.property);

const propertyGetPropertyViewOfferSessionsAttemptingAttempting = (state: RootState): boolean => property.getPropertyViewOfferSessionsAttempting(state.property);
const propertyGetPropertyViewOfferSessionsErrorError = (state: RootState): string => property.getPropertyViewOfferSessionsError(state.property);
const propertyGetPropertyViewOfferSessions = (state: RootState): REAGetOfferResponse[] | null => property.getPropertyViewOfferSessions(state.property);

const propertySetPropertySetPropertySubmitOfferAttemptinggAttempting = (state: RootState): boolean => property.setPropertySetPropertySubmitOfferAttempting(state.property);
const propertySetPropertySetPropertySubmitOffersErrorError = (state: RootState): string => property.setPropertySetPropertySubmitOffersError(state.property);
const propertySetPropertySetPropertySubmitOffers = (state: RootState): string => property.setPropertySetPropertySubmitOffers(state.property);
const propertySetRequestKeyAttempt = (state: RootState): boolean => property.setRequestKeyAttempt(state.property);
const propertySetRequestKeyFailure = (state: RootState): string => property.setRequestKeyFailure(state.property);
const propertySetRequestKeySuccess = (state: RootState): string => property.setRequestKeySuccess(state.property);

export default {
    // Auth
    authGetStartupAttempting,
    authGetStartupError,

    authGetLoginAttempting,
    authGetLoginError,

    authGetAuthToken,

    authGetSignupAttempting,
    authGetSignupError,

    authGetVerifySignUpAttempt,
    authGetVerifySignUpError,
    authGetVerifySignUp,

    authGetUnsubscribeAttempt,
    authGetUnsubscribeError,

    // UI
    getUiSelectedLanguage,
    getUiSelectedTabView,
    getUiModulesCreateModal,
    getUiCurrentPathName,

    // User
    userGetUserInfoAttempting,
    userGetUserInfoError,
    userGetUserInfo,
    userGetUserInfoName,

    userUpdateUserInfoAttempting,
    userUpdateUserInfoError,

    userGetIsRENUploadModalOpen,

    userGetRENUploadUrlAttempting,
    userGetRENUploadUrlError,

    userGetRENUploadUrl,

    userUploadRENDocumentAttempting,
    userUploadRENDocumentError,

    // Property
    propertyGetPropertyListingAttempting,
    propertyGetPropertyListingError,

    propertyGetPropertyListing,

    propertyGetPropertyDetailsAttempting,
    propertyGetPropertyDetailsError,

    propertyGetPropertyDetails,

    propertyGetCoBrokeUploadUrlAttempting,
    propertyGetCoBrokeUploadUrlError,

    propertyGetCoBrokeUploadUrl,

    propertyUploadCoBrokeDocumentAttempting,
    propertyUploadCoBrokeDocumentError,
    propertyUploadCoBrokeDocument,

    propertyGetPropertyCoBrokeDocumentTemplateAttempting,
    propertyGetPropertyCoBrokeDocumentTemplateError,
    propertyGetPropertyCoBrokeDocumentTemplate,

    propertySetIsCoBrokeUploadModalOpen,

    propertyGetSelectedPropertyId,

    propertyGetPropertyViewOfferSessionsAttemptingAttempting,
    propertyGetPropertyViewOfferSessionsErrorError,

    propertyGetPropertyViewOfferSessions,

    propertySetPropertySetPropertySubmitOfferAttemptinggAttempting,
    propertySetPropertySetPropertySubmitOffersErrorError,
    propertySetPropertySetPropertySubmitOffers,
    propertySetRequestKeyAttempt,
    propertySetRequestKeyFailure,
    propertySetRequestKeySuccess,
};
