import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Oval } from 'react-loader-spinner';

import { RootState, AppDispatch } from 'redux/store';
import Translate from 'lib/translate';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { Button, BreadCrumb, Colors } from '@dm/bigfish';

import NavActions from 'lib/NavActions';

import MainContainer from '../../components/MainContainer';
import CarelineButton from '../../components/CarelineButton';

interface VerifySignUpProps {
    getVerifySignUpLoading: boolean;
    isVerified: boolean;
    verifySignUp(id: string): void;
    clearVerifySignUp(): void;
}

const VerifySignUp = (props: VerifySignUpProps): JSX.Element => {
    const [breadCrumb] = useState([
        {
            label: Translate.t('VerifySignUp.VerifyEmailBcSignUp'),
            onClick: () => { NavActions.navToSignUp(); },
        },
        {
            label: Translate.t('VerifySignUp.VerifyEmailBcReaSignUp'),
            onClick: () => { NavActions.navToSignUp(); },
        },
        {
            label: Translate.t('VerifySignUp.VerifyEmailBcVerificationEmail'),
            onClick: () => { return false; },
        },
    ]);

    const { getVerifySignUpLoading, isVerified, verifySignUp, clearVerifySignUp } = props;

    const params = useParams();
    const { uuid } = params;

    useEffect(() => {
        if (uuid) {
            verifySignUp(uuid);
        }

        return () => {
            clearVerifySignUp();
        };
    }, []);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const renderContent = () => {
        if (getVerifySignUpLoading) {
            return (
                <LoadingContainer>
                    <Oval
                        height={200}
                        width={200}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </LoadingContainer>
            );
        }

        return (
            <>
                <Container>
                    {isVerified
                        ? (
                            <>
                                <Title>{Translate.t('VerifySignUp.VerifySignUpTitleSuccess')}</Title>
                                <Text>
                                    {Translate.t('VerifySignUp.VerifySignUpDescSuccess')}
                                </Text>
                            </>
                        ) : (
                            <>
                                <Title>{Translate.t('VerifySignUp.VerifySignUpTitleFailed')}</Title>
                                <Text>
                                    {Translate.t('VerifySignUp.VerifySignUpDescFailed')}
                                </Text>
                            </>
                        )}
                    <ButtonContainer>
                        <Button label={Translate.t('VerifySignUp.VerifySignUpLabel')} onClick={() => NavActions.navToLogin()} />
                    </ButtonContainer>
                </Container>
                <CarelineContainer>
                    <CarelineButton />
                </CarelineContainer>
            </>
        );
    };

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Ui.UiBack')} onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                {renderContent()}
            </MainContainer>
        </>
    );
};

const Container = styled.div`
    margin: 0 auto;
    margin-top: 80px;
    padding: 20px;
    max-width: 554px;
    box-sizing: border-box;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const Title = styled.h3`
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 12px;
`;

const Text = styled.p`
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: #696969;
    margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: end;
`;

const CarelineContainer = styled.div`
    position: absolute;
    bottom: 23px;
    right: 21px;
`;

const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -80%);
`;

const mapStateToProps = (state: RootState) => ({
    getVerifySignUpLoading: Selectors.authGetVerifySignUpAttempt(state),
    isVerified: Selectors.authGetVerifySignUp(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    verifySignUp: (id: string) =>
        dispatch(Actions.authVerifySignUpAttempt({ id })),
    clearVerifySignUp: () => dispatch(Actions.clearVerifySignUp()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifySignUp);
