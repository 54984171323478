export default {
    Ui: {
        UiCareline: 'Hubungi Kami',
        UiBack: 'Kembali',
        UiCompanyName: 'Dormeo Homes',
        UiSearchTitle: 'Beli',
        UiProfileButtonTitle: 'Daftar masuk',
    },

    Header: {
        HeaderMyProfile: 'Profil Saya',
        HeaderMyProperties: 'Hartanah Saya',
        HeaderSettings: 'Tetapan',
        HeaderHelpCenter: 'Pusat Bantuan',
        HeaderLogOut: 'Log Keluar',
    },

    BreadCrumb: {
        BreadCrumbDashboard: 'Dashboard',
        BreadCrumbWelcomeBack: 'Welcome Back',
    },

    Search: {
        SearchBcSearch: 'Search',
        SearchBcPropertiesResult: 'Properties - Results',
        SearchNoProperties: 'No Properties Found',
        SearchFiltersTitle: 'All Property Listings',
        SearchHeaderName: 'Name',
        SearchHeaderUnitName: 'Unit Name',
        SearchHeaderStatus: 'Status',
        SearchHeaderType: 'Type',
        SearchHeaderCoBrokeStatus: 'Co Broke Status',
        SearchHeaderActions: 'Actions',

        SearchPropertyApproved: 'Approved',
        SearchPropertyRejected: 'Rejected',
        SearchPropertyDeactivated: 'Deactivated',
        SearchPropertyDraftProperty: 'Draft Property',
        SearchPropertyPendingApproval: 'Pending Approval',
        SearchPropertyTenanted: 'Tenanted',
        SearchPropertyVacant: 'Vacant',

        SearchPropertyApartment: 'Apartment',
        SearchPropertyLanded: 'Landed',

        SearchButtonApprove: 'Approve',
        SearchButtonReject: 'Reject',
    },

    Profile: {
        ProfileBcMyProfile: 'Profil Saya',
        ProfileDescription: 'Sila isikan butiran untuk kategori yang disediakan bagi melengkapkan persediaan akaun anda.',
        ProfileSignupTitle: 'Butiran Profile',
        ProfileFirstName: 'Nama Pertama',
        ProfileLastName: 'Nama Terakhir',
        ProfilePhoneNumber: 'Nombor Telefon',
        ProfileRenNumber: 'Nombor REN',
        ProfileCompanyName: 'Nama Syarikat',
        ProfileAddress1: 'Alamat 1',
        ProfileAddress2: 'Alamat 2',
        ProfileCity: 'Bandar',
        ProfileCountry: 'Negara',
        ProfileState: 'Negeri',
        ProfilePostcode: 'Poskod',
        ProfileWelcomeModalTitle: 'Selamat Datang ke Dormeo!',
        ProfileWelcomeModalDesc1: 'Terima kasih kerana mendaftar ke Dormeo Homes, laman web perkhidmatan pengurusan hartanah terkemuka.',
        ProfileWelcomeModalDesc2: 'Lengkapkan persediaan akaun anda dengan mengisi butiran peribadi.',
        ProfileWelcomeModalContinueButton: 'Teruskan',
        ProfileSubmitButton: 'Hantar',
        ProfileEditProfileButton: 'Edit Profile',
        ProfileViewRENDocButton: 'View REN Document',
        ProfileUploadRENDocButton: 'Upload REN Document',
        ProfileRENDocTitle: 'REN Document',
    },

    Login: {
        LoginLabel: 'Daftar Masuk',
        LoginReaLogin: 'Daftar Masuk Ejen',
        LoginEmail: 'Alamat email anda',
        LoginPlaceholder: 'cth. example123@gmail.com',
        LoginPlaceholderPassword: 'masukkan katalaluan',
        LoginRememberMe: 'Ingat saya',
        LoginPassword: 'Kata laluan anda',
        LoginHaveYou: 'Adakah anda',
        LoginForgotPassword: 'lupa kata laluan?',
        LoginNewTo: 'Baru untuk Dormeo?',
        LoginJoinNow: 'Daftar sekarang',
        LoginErrorEmail: 'Sila masukkan alamat emel yang sah',
        LoginBcLogin: 'Daftar Masuk',
        LoginBcReaLogin: 'Daftar Masuk Ejen',
        LoginEmptyInputError: 'Sila lengkapkan segala input',
    },

    SignUp: {
        SignUpTitle: 'Daftar Baru REA',
        SignUpLabel: 'Daftar Baru',
        SignUpReaSignup: 'Daftar Pemilik Baru',
        SignUpToastError: 'Password and confirm password must be match',
        SignUpErrorEmail: 'Enter a valid email address',
        SignUpEmail: 'Alamat Emel Anda',
        SignUpPlaceholderEmail: 'cth. example123@gmail.com',
        SignUpPlaceholderPassword: 'sila masukkan kata laluan',
        SignUpPassword: 'Kata Laluan Anda',
        SignUpConfirmPassword: 'Pengesahan Kata Laluan Anda',
        SignUpAlreadyUser: 'Sudah ada akaun?',
        SignUpLogin: 'Daftar masuk',
        SignUpBcSignUp: 'Daftar Baru',
        SignUpBcReaSignUp: 'Daftar Pemilik Baru',
        SignUpPasswordStrengthText: 'Katalaluan harus mempunyai sekurang-kurangnya 1 huruf besar, 1 huruf kecil, 1 simbol dan sekurang-kurangnya 5 karakter',
        SignUpRENModalTitle: 'Muat naik Dokumen REN',
        SignUpRENModalInputLabel: 'Nama Dokumen',
        SignUpRENModalUploadButtonLabel: 'Muat naik Dokumen',
        SignUpRENModalUploadDisclaimer: 'Nota: hanya menerima fail jenis .pdf, .jpg, .jpeg dan .png',
        SignUpRENModalNoDocumentNameError: 'Sila isikan nama dokumen',
        SignUpRENDocumentUploadedMessage: 'Dokumen REN telah di muat naik',
    },

    VerifySignUp: {
        VerifySignUpLabel: 'Okay',
        VerifySignUpTitleSuccess: 'Your Account Has Been Verified!',
        VerifySignUpDescSuccess: 'Your Owner account has been verified, please login your Owner account to begin using Dormeo Homes services.',
        VerifySignUpTitleFailed: 'Your Account Has Not Been Verified!',
        VerifySignUpDescFailed: 'Your Owner account has not been verified, please check your email',
        VerifyEmailBcSignUp: 'Signup',
        VerifyEmailBcOwnerSignUp: 'Owner Signup',
        VerifyEmailBcVerificationEmail: 'Email Verification',

        VerifySignUplabel: 'signup',
        VerifySignUplabelOwner: 'owner signup',
        VerifySignUplabelemailverification: 'email verification',
        VerifySignUpTitleVerified: 'Your Account Has Been Verified!',
        VerifySignUpTextVerifiec: 'Your Owner account has been verified, please login your Owner account to begin using Dormeo Homes services.',
        VerifySignUpTitleNotVerified: 'Your Account Has Not Been Verified!',
    },

    VerificationEmail: {
        VerificationEmailLabel: 'Okay, Got It.',
        VerificationEmailTitle: 'Email Verification',
        VerificationEmailDesc: 'Your account requires an email verification, please check your email.',
        VerificationEmailBcSignUp: 'Signup',
        VerificationEmailBcReaSignUp: 'REA Signup',
        VerificationEmailBcVerificationEmail: 'Email Verification',
    },

    Modules: {
        ModulesStats: 'Stats',
        ModulesModules: 'Modules',

        ModulesPendingActivities: 'Pending Activities',
        ModulesPendingActivitiesButton: 'Go to Pending Activities page',
        ModulesPendingActivitiesEmpty: 'You have no pending activities',
        ModulesPendingApprovals: 'Pending Approvals',
        ModulesPendingApprovalsButton: 'Go to Approvals page',
        ModulesPendingApprovalsEmpty: 'You have no pending approvals',

        ModulesSearch: 'Carian',
        ModulesSearchDesc: 'Carian untuk penduduk dan penyenaraian hartanah',
        ModulesReports: 'Laporan',
        ModulesReportsDesc: 'Semak laporan atas penyenaraian hartanah',
        ModulesCurrentProperties: 'Hartanah Terkini',
        ModulesCurrentPropertiesDesc: 'Semak butiran hartanah terkini',
        ModulesTenants: 'Penyewa',
        ModulesTenantsDesc: 'Urus butiran penyewa',
        ModulesBids: 'Bidaan',
        ModulesBidsDesc: 'Lihat butiraan bidaan terkini',
        ModulesCoBrooking: 'Co-Brookings',
        ModulesCoBrookingDesc: 'View current co-brooking details',
    },

    Modal: {
        ModulesCreateTitle: 'What are you creating?',
        ModulesCreateDesc: 'Please choose the correct option to start creation process.',
        ModulesCreateBtnOwner: 'Owner Account',
        ModulesCreateBtnListing: 'Property Listing',
    },

    CreateNew: {
        CreateNewBcDashboard: 'Dashboard',
        CreateNewBcCreate: 'Create',
        CreateNewBcOwner: 'Owner Account Setup Process',
        CreateNewBcError1: 'Please fill and complete the attach properties field and acc details!',
        CreateNewBcDesc: 'Please ensure each step of the process is filled in correctly to ensure no errors.',
        CreateNewStep1: 'step 1:',
        CreateNewOwnerDetails: 'Owner’s Details',
        CreateNewDesc: 'Please fill in the details for the following categories to complete your account setup.',
        CreateNewTitle: 'Personal Details',
        CreateNewLabelTitle: 'Title',
        CreateNewLabelGender: 'Gender',
        CreateNewLabelCPAddress1: 'Corresponding Address 1',
        CreateNewLabelCPAddress2: 'Corresponding Address 2',
        CreateNewLabelFirstName: 'First Name',
        CreateNewLabelLastName: 'Last Name',
        CreateNewLabelIDNo: 'ID No',
        CreateNewLabelCity: 'City/ Town',
        CreateNewLabelPassport: 'Passport No',
        CreateNewLabelMobile: 'Mobile No',
        CreateNewLabelPostcode: 'Postcode',
        CreateNewLabelCountryOrigin: 'Country of Origin',
        CreateNewLabelHouseTel: 'House Tel.',
        CreateNewLabelState: 'State',
        CreateNewLabelRace: 'Race',
        CreateNewLabelCountryResidence: 'Country of Residence',
        CreateNewLabelCountry: 'Country',
        CreateNewLabelDOB: 'Date of Birth',
        CreateNewLabelBankType: 'Bank Type',
        CreateNewLabelCurrency: 'Currency',
        CreateNewLabelBranchAddress: 'Bank Address',
        CreateNewLabelBranchName: 'Bank Name',
        CreateNewLabelBranchAccNo: 'Bank Acc No',
        CreateNewLabelSwiftCode: 'Swift Code',
        CreateNewLabelCompanyName: 'Company Name',
        CreateNewLabelDesignation: 'Company Designation',
        CreateNewLabelOfficeNo: 'Office No.',
        CreateNewLabelFullName: 'Full Name',
        CreateNewLabelRelationship: 'Relationship',
        CreateNewLabelEmail: 'Email',
        CreateNewErrorEmail: 'Please insert a valid email address',
        CreateNewErrorPass: 'Please insert a valid password',
        CreateNewErrorPassConfirm: 'Password confirmation does not match with password',
        CreateNewFinancialDetails: 'Financial Details',
        CreateNewCompDetails: 'Company Details',
        CreateNewEmergencyDetails: 'Emergency Contact Details',
        CreateNewStep2: 'step 2:',
        CreateNewAttachProp: 'Attach Properties',
        CreateNewNewProj: 'new project',
        CreateNewNoPropFound: 'No Property Found',
        CreateNewStep3: 'step 3:',
        CreateNewAccDetails: 'Account Details',
        CreateNewBtnSubmit: 'Submit',
        CreateNewBtnClear: 'Clear',
        CreateNewBtnConfirm: 'Confirm',
    },

    OwnersReport: {
        OwnersReportBcDashboard: 'Dashboard',
        OwnersReportBcOwners: 'Owners',
        OwnersReportBcOwnerDetails: 'Owner Details',
        OwnersReportFilterTitle: 'Owner Accounts Results',
        OwnersReportFilterCountry: 'Country',
        OwnersReportFilterLocation: 'Location',
        OwnersReportFilterStatus: 'Status',
        OwnersReportFilterProperties: 'Properties',
        OwnersReportFilterFinance: 'Finance',
        OwnersReportFilterDefault: 'Default',
        OwnersReportSaveSearch: 'Save search',
        OwnersReportHeaderName: 'Name',
        OwnersReportHeaderPackages: 'Packages',
        OwnersReportHeaderAge: 'Age',
        OwnersReportHeaderGender: 'Gender',
        OwnersReportHeaderLocation: 'Location',
        OwnersReportHeaderProp: 'Properties',
        OwnersReportHeaderMonthlyExpense: 'Monthly Expense',
        OwnersReportError1: 'No records found',
        OwnersReportError2: 'If using a custom view, try adjusting the filters.',
        OwnersReportError3: 'Otherwise, create some data!',
        OwnersReportError4: 'Something error',
        OwnersReportError5: 'Please contact system administrator for details.',
        OwnersReportError6: 'Otherwise, please try again!',
        OwnersReportRecentViewed: 'Recently Viewed',
        OwnersReportMoreLocations: 'More Locations',
        OwnersReportButtonMore: 'More',
        OwnersReportManagedProperties: 'Managed Properties',
    },

    PropertyReport: {
        PropertyReportBcDashboard: 'Dashboard',
        PropertyReportBcProperty: 'Property Listings',
        PropertyReportFilterTitle: 'All Property Listings',
        PropertyReportFilterBuildingTypes: 'All Building Types',
        PropertyReportFilterStatus: 'Status',
        PropertyReportFilterTypes: 'Types',
        PropertyReportFilterSize: 'Built-up Size',
        PropertyReportFilterConfig: 'Configurations',
        PropertyReportFilterProp: 'Show Properties',
        PropertyReportFilterDefault: 'Default',
        PropertyReportSaveSearch: 'Save search',
        PropertyReportHeaderName: 'Name',
        PropertyReportHeaderStatus: 'Status',
        PropertyReportHeaderSize: 'Size',
        PropertyReportHeaderType: 'Type',
        PropertyReportHeaderConfig: 'Configuration',
        PropertyReportHeaderOwner: 'Owner',
        PropertyReportHeaderLocation: 'Location',
        PropertyReportError1: 'No records found',
        PropertyReportError2: 'If using a custom view, try adjusting the filters.',
        PropertyReportError3: 'Otherwise, create some data!',
        PropertyReportError4: 'Something error',
        PropertyReportError5: 'Please contact system administrator for details.',
        PropertyReportError6: 'Otherwise, please try again!',
        PropertyReportRecentViewed: 'Recently Viewed',
        PropertyReportMoreLocations: 'More Locations',
        PropertyReportButtonMore: 'More',
    },

    PropertyRpDetails: {
        PropertyRpDetailsBcDashboard: 'Dashboard',
        PropertyRpDetailsBcPropertyListing: 'Property Listings',
        PropertyRpDetailsTitlePropDetails: 'Property Details',
        PropertyRpDetailsTitleRoomDetails: 'Room Details',
        PropertyRpDetailsTitleAdminControls: 'Admin Controls',
        PropertyRpDetailsBtnApprove: 'Approve Property',
        PropertyRpDetailsBtnReject: 'Reject Property',
        PropertyRpDetailsBtnContact: 'Contact Owner',
        PropertyRpDetailsLabelPropType: 'Property Type',
        PropertyRpDetailsLabelPropStatus: 'Property Status',
        PropertyRpDetailsLabelLandTitle: 'Land Title',
        PropertyRpDetailsLabelUnitType: 'Unit Type',
        PropertyRpDetailsLabelPropTitleType: 'Property Title Type',
        PropertyRpDetailsLabelTenure: 'Tenure',
        PropertyRpDetailsLabelStorey: 'Storey',
        PropertyRpDetailsLabelBtSize: 'Built-up Size',
        PropertyRpDetailsLabelBtPrice: 'Built-Up Price',
        PropertyRpDetailsLabelBtDim: 'Built-Up Dimension',
        PropertyRpDetailsLabelRefNo: 'Reference No.',
        PropertyRpDetailsLabelPostedDate: 'Posted Date',
        PropertyRpDetailsLabelFurnishing: 'Furnishing',
        PropertyRpDetailsLabelOccupancy: 'Occupancy',
        PropertyRpDetailsLabelFacingDirection: 'Facing Direction',
        PropertyRpDetailsErrorNoImagesFound: 'No images found',
        PropertyRpDetailsInstruction: 'Scroll horizontally to show other images',
        PropertyRpDetailsOfferText: 'Place an offer for this property?',
    },

    PaymentsReport: {
        PaymentsRpBcDashboard: 'Dashboard',
        PaymentsRpBcPayments: 'Payments',
        PaymentsRpTitle: 'Payments',
        PaymentsRpHeaderID: 'ID',
        PaymentsRpHeaderName: 'Name',
        PaymentsRpHeaderCreatedAt: 'Created At',
        PaymentsRpHeaderTotalPrice: 'Total Price',
        PaymentsRpHeaderPackages: 'Packages',
        PaymentsRpHeaderStatus: 'Status',
        PaymentsRpHeaderActions: 'Actions',
        PaymentsRpActionButtonApprove: 'Approve',
        PaymentsRpActionButtonReject: 'Reject',
        PaymentsRpApproveLowerCase: 'approve',
        PaymentsRpRejectLowerCase: 'reject',
        PaymentsRpApproveRejectMsg1: 'Are you sure you want to',
        PaymentsRpApproveRejectMsg2: 'this payment?',
    },

    Error: {
        GeneralError: 'Maaf, Masalah tidak diketahui telah berlaku. Sila cuba semula.',
        ErrorGeneralErrorScreenBc: 'Error',
        ErrorGeneralErrorScreenText: 'Sorry, something went wrong.',
    },

    Unsubscribe: {
        UnsubscribeTitle: 'Unsubscribe',
        UnsubscribeDesc: 'Thank you, you have been successfully removed from this subscriber list and wont receive any further emails from us',
    },
};
