import { put, call, takeEvery } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';
import { create } from 'apisauce';
import PropertyGateway from 'api/Property';

import { delay } from 'redux-saga/effects';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';

import Translate from 'lib/translate';
import { UploadCoBrokeDocumentActionPayload } from 'redux/slices/property/types';
import { PropertyStatus, PropertyTypeEnum } from '@dm/types';

export default function* watchUploadCoBrokeDocument(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/uploadCoBrokeDocumentAttempt', handleUploadCoBrokeDocument, api);
}

function* handleUploadCoBrokeDocument(api: PropertyGateway, data: UploadCoBrokeDocumentActionPayload) {
    const { preSignedUrl, document, docType } = data.payload;

    const formData = new FormData();
    formData.append('file', document);

    const s3Api = create({
        baseURL: preSignedUrl,
        headers: {
            'content-type': `application/${docType}`,
        },
    });

    const response = yield* call(s3Api.put, '', document);

    if (!response.ok) {
        yield put(Actions.uploadCoBrokeDocumentFailure(Translate.t('Error.GeneralError')));
        toast.error(Translate.t('Error.GeneralError'));
    } else {
        yield put(Actions.uploadCoBrokeDocumentSuccess(Translate.t('Search.UploadedSuccessfully')));
        toast.success(Translate.t('Search.DocumentUploaded'));
        yield put(Actions.getPropertyListingAttempt({ propertyName: '', propertyType: PropertyTypeEnum.Apartment, propertyStatus: PropertyStatus.approved, index: 1 }));
    }
}
