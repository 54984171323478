import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Oval } from 'react-loader-spinner';

import { RootState, AppDispatch } from 'redux/store';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { BreadCrumb, Colors } from '@dm/bigfish';

import NavActions from 'lib/NavActions';
import Translate from 'lib/translate';

import CarelineButton from '../../components/CarelineButton';
import MainContainer from '../../components/MainContainer';

interface UnsubscribeProps {
    getUnsubscribeLoading: boolean;
    unsubscribe(hash: string): void;
}

const Unsubscribe = (props: UnsubscribeProps): JSX.Element => {
    const { getUnsubscribeLoading, unsubscribe } = props;

    const [breadCrumb] = useState([
        {
            label: Translate.t('Unsubscribe.UnsubscribeTitle'),
            onClick: () => { return false; },
        },
    ]);

    const params = useParams();
    const { hash } = params;

    useEffect(() => {
        if (hash) {
            unsubscribe(hash);
        }
    }, []);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    const renderContent = () => {
        if (getUnsubscribeLoading) {
            return (
                <LoadingContainer>
                    <Oval
                        height={200}
                        width={200}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </LoadingContainer>
            );
        }

        return (
            <>
                <Container>
                    <Title>
                        {Translate.t('Unsubscribe.UnsubscribeTitle')}
                    </Title>
                    <Text>
                        {Translate.t('Unsubscribe.UnsubscribeDesc')}
                    </Text>
                </Container>
                <CarelineContainer>
                    <CarelineButton />
                </CarelineContainer>
            </>
        );
    };

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Ui.UiBack')} onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                {renderContent()}
            </MainContainer>
        </>
    );
};

const Container = styled.div`
    margin: 0 auto;
    margin-top: 80px;
    padding: 20px;
    max-width: 554px;
    box-sizing: border-box;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const Title = styled.h3`
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 12px;
`;

const Text = styled.p`
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #696969;
    margin-bottom: 20px;
`;

const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -80%);
`;

const CarelineContainer = styled.div`
    position: absolute;
    bottom: 23px;
    right: 21px;
`;

const mapStateToProps = (state: RootState) => ({
    getUnsubscribeLoading: Selectors.authGetUnsubscribeAttempt(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    unsubscribe: (hash: string) =>
        dispatch(Actions.authUnsubscribeAttempt({ hash })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Unsubscribe);
