import React, { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';

interface StyledButtonProps {
    disabled: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;

  color: ${props => (props.disabled ? 'grey' : 'black')}
`;

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 3px 40px;
    border: 1px solid rgb(0,0,0,0.3);
    border-radius: 50px;
`;

const InnerContainer = styled.div`
    width: 100%;
`;

const StyledPaginator = styled(ReactPaginate).attrs({
    // You can redifine classes here, if you want.
    activeClassName: 'active',
})`
    display: flex;
    justify-content: space-between;
    list-style-type: none;

    padding: 5px 20px;
    li a {
      border-radius: 7px;
      cursor: pointer;
      font-weight: 400;
    }

    li.previous a,
    li.next a,
    li.break a {
    }

    li.active a {
      font-weight: 700;
    }

    li.disabled a {
      color: grey;
    }

    li.disable,
    li.disabled a {
      cursor: default;
    }
`;

interface CustomPaginatorEvent {
    selected: number;
}

interface CustomPaginatorProps {
    pageCount: number;
    forcePage: number;
    onPageChange: (pageNum: number) => void;
}

const CustomPaginator: FunctionComponent<CustomPaginatorProps> = (props: CustomPaginatorProps) => {
    const { pageCount, onPageChange, forcePage } = props;

    const pageChangeHandler = (page: number) => {
        onPageChange(page);
    };

    return (
        <MainContainer>
            <StyledButton
                disabled={forcePage === 0}
                onClick={() => pageChangeHandler(0)}
            >
                First
            </StyledButton>
            <InnerContainer>
                <StyledPaginator
                    pageCount={pageCount}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={0}
                    nextLabel='>'
                    previousLabel='<'
                    onPageChange={(e: CustomPaginatorEvent) => pageChangeHandler(e.selected)}
                    forcePage={forcePage}
                />
            </InnerContainer>
            <StyledButton
                disabled={forcePage === pageCount - 1}
                onClick={() => pageChangeHandler(pageCount - 1)}
            >
                Last
            </StyledButton>
        </MainContainer>

    );
};

export default CustomPaginator;
