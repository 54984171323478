import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import PropertyGateway from 'api/Property';
import { GatewayResponseStatus } from 'api/types/types';
import { GetPropertyListingActionPayload } from 'redux/slices/property/types';

export default function* watchGetPropertyListing(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/getPropertyListingAttempt', handleGetPropertyListing, api);
}

function* handleGetPropertyListing(api: PropertyGateway, data: GetPropertyListingActionPayload) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const { propertyName, propertyStatus, propertyType, index } = data.payload;

    const response = yield* call([api, api.getPropertyListing], { authToken, name: propertyName, status: propertyStatus, type: propertyType, index });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getPropertyListingFailure(response.message));
        return;
    }

    yield put(Actions.getPropertyListingSuccess(response.data));
}
