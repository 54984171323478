import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import FileSaver, { saveAs } from 'file-saver';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import Translate from 'lib/translate';
import { toast } from 'react-toastify';
import PropertyGateway from 'api/Property';
import { GatewayResponseStatus } from 'api/types/types';
import { GetCoBrokeDocumentTemplateActionPayload } from 'redux/slices/property/types';

export default function* watchGetCoBrokeTemplate(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/getPropertyCoBrokeDocumentTemplateAttempt', handleGetPropertyListing, api);
}

function* handleGetPropertyListing(api: PropertyGateway, data: GetCoBrokeDocumentTemplateActionPayload) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const { documentTemplate, propertyId } = data.payload;

    const response = yield* call([api, api.getCoBrokeDocumentTemplate], { authToken, documentTemplate, propertyId });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getPropertyCoBrokeDocumentTemplateFailure(response.message));
        toast.error(Translate.t('Search.DownloadFailed'));
        return;
    }

    yield put(Actions.getPropertyCoBrokeDocumentTemplateSuccess(response.data));

    const urlLink = `data:image/png;base64,${response.data}`;
    const documentName = 'Template.pdf';

    saveAs(
        urlLink,
        documentName,
    );
}
