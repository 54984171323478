import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import Translate from 'lib/translate';

import PropertyGateway from 'api/Property';
import { GatewayResponseStatus } from 'api/types/types';
import { SetRequestKeyActionPayload } from 'redux/slices/property/types';
import { toast } from 'react-toastify';

export default function* watchSetPropertyRequestKey(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/setRequestKeyAttempt', handleSetPropertyRequestKey, api);
}

function* handleSetPropertyRequestKey(api: PropertyGateway, data: SetRequestKeyActionPayload) {
    const authToken = yield* select(Selectors.authGetAuthToken);
    const { propertyId, requestKeyDate } = data.payload;

    const response = yield* call([api, api.setRequestPropertyKey], { authToken, propertyId, requestKeyDate });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.setRequestKeyFailure(response.message));
        toast.error(Translate.t('PropertyRequestKey.PropertySetKeyRequestFail'));
        return;
    }

    yield put(Actions.setRequestKeySuccess(response.data));
    toast.success(`${Translate.t('PropertyRequestKey.PropertySetKeyRequestSuccess')} ${data.payload.requestKeyDate}`);
}
