import React, { useState } from 'react';
import styled from 'styled-components';

import { Button, BreadCrumb } from '@dm/bigfish';

import NavActions from 'lib/NavActions';
import Translate from 'lib/translate';

import CarelineButton from '../../components/CarelineButton';
import MainContainer from '../../components/MainContainer';

const VerificationEmail = (): JSX.Element => {
    const [breadCrumb] = useState([
        {
            label: Translate.t('VerificationEmail.VerificationEmailBcSignUp'),
            onClick: () => { NavActions.navToSignUp(); },
        },
        {
            label: Translate.t('VerificationEmail.VerificationEmailBcReaSignUp'),
            onClick: () => { NavActions.navToSignUp(); },
        },
        {
            label: Translate.t('VerificationEmail.VerificationEmailBcVerificationEmail'),
            onClick: () => { return false; },
        },
    ]);

    const onBackPressedBreadCrumb = () => {
        NavActions.navBack();
    };

    return (
        <>
            <BreadCrumb backLabel={Translate.t('Ui.UiBack')} onBackPressed={onBackPressedBreadCrumb} data={breadCrumb} />
            <MainContainer>
                <Container>

                    <Title>{Translate.t('VerificationEmail.VerificationEmailTitle')}</Title>
                    <Text>{Translate.t('VerificationEmail.VerificationEmailDesc')}</Text>

                    <ButtonContainer>
                        <Button label={Translate.t('VerificationEmail.VerificationEmailLabel')} onClick={() => NavActions.navToLogin()} />
                    </ButtonContainer>
                </Container>
                <CarelineContainer>
                    <CarelineButton />
                </CarelineContainer>
            </MainContainer>
        </>
    );
};

const Container = styled.div`
    margin: 0 auto;
    margin-top: 80px;
    padding: 20px;
    max-width: 554px;
    box-sizing: border-box;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const Title = styled.h3`
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 12px;
`;

const Text = styled.p`
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #696969;
    margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: end;
`;

const CarelineContainer = styled.div`
    position: absolute;
    bottom: 23px;
    right: 21px;
`;

export default VerificationEmail;
