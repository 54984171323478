import React, { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import { Colors, Fonts, Button } from '@dm/bigfish';
import { CoBrokeStatusEnum, DocumentTemplate, PropertyImage, PropertyStatus, PropertyTypeEnum } from '@dm/types';

import Translate from 'lib/translate';
import NavActions from 'lib/NavActions';
import icons from 'assets/icons';

interface PropertyCardProps {
    propertyId: string;
    name: string;
    images: PropertyImage[];
    status: PropertyStatus;
    coBrokeStatus: CoBrokeStatusEnum;
    type: PropertyTypeEnum;
    isModalOpen: boolean;
    unitNumber: string | undefined;
    setUploadCoBrokeDocumentModalOpen: (state: boolean) => void;
    setTemplateDownload: (documentTemplate: string, propertyId: string) => void;
    setSelectedPropertyId: (propertyId: string) => void;
    clearPropertyViewOfferSessions: () => void;
}

const PropertyCard: FunctionComponent<PropertyCardProps> = (props: PropertyCardProps) => {
    const {
        propertyId,
        name,
        images,
        status,
        coBrokeStatus,
        type,
        isModalOpen,
        unitNumber,
        setUploadCoBrokeDocumentModalOpen,
        setTemplateDownload,
        setSelectedPropertyId,
        clearPropertyViewOfferSessions,
    } = props;

    const [statusText, setStatusText] = useState('');
    const [typeText, setTypeText] = useState('');
    const [coBrokeStatusText, setCoBrokeStatusText] = useState('');

    useEffect(() => {
        switch (status) {
            case PropertyStatus.approved: setStatusText(Translate.t('Search.SearchPropertyApproved')); break;
            case PropertyStatus.rejected: setStatusText(Translate.t('Search.SearchPropertyRejected')); break;
            case PropertyStatus.deactivated: setStatusText(Translate.t('Search.SearchPropertyDeactivated')); break;
            case PropertyStatus.draftProperty: setStatusText(Translate.t('Search.SearchPropertyDraftProperty')); break;
            case PropertyStatus.pendingApproval: setStatusText(Translate.t('Search.SearchPropertyPendingApproval')); break;
            case PropertyStatus.tenanted: setStatusText(Translate.t('Search.SearchPropertyTenanted')); break;
            case PropertyStatus.vacant: setStatusText(Translate.t('Search.SearchPropertyVacant')); break;
            default:
        }

        switch (type) {
            case PropertyTypeEnum.Apartment: setTypeText(Translate.t('Search.SearchPropertyApartment')); break;
            case PropertyTypeEnum.Landed: setTypeText(Translate.t('Search.SearchPropertyLanded')); break;
            default:
        }

        switch (coBrokeStatus) {
            case CoBrokeStatusEnum.Pending: setCoBrokeStatusText('Pending'); break;
            case CoBrokeStatusEnum.Approved: setCoBrokeStatusText('Approved'); break;
            case CoBrokeStatusEnum.Rejected: setCoBrokeStatusText('Rejected'); break;
            default: setCoBrokeStatusText('No Co Broke Uploaded'); break;
        }
    }, []);

    const downloadTemplate = () => {
        setTemplateDownload(DocumentTemplate.CoBroke.toString(), propertyId);
    };

    const goToProperty = () => {
        NavActions.navToPropertyDetails(propertyId);
        clearPropertyViewOfferSessions();
    };

    const uploadDocumentClickHandler = () => {
        setUploadCoBrokeDocumentModalOpen(!isModalOpen);
        setSelectedPropertyId(propertyId);
    };

    const renderActionButtons = () => {
        if (typeof coBrokeStatus === 'string' || coBrokeStatus === CoBrokeStatusEnum.Rejected) {
            return (
                <ButtonMainContainer>

                    <InputItemContainer>
                        <UploadButton
                            onClick={() => downloadTemplate()}
                        >
                            <UploadIcon
                                src={icons.UploadIcon}
                            />

                            <UploadDocumentText>
                                {(Translate.t('Search.DownloadTemplate'))}
                            </UploadDocumentText>
                        </UploadButton>

                    </InputItemContainer>

                    <InputItemContainer>
                        <UploadButton
                            onClick={uploadDocumentClickHandler}
                        >
                            <UploadIcon
                                src={icons.UploadIcon}
                            />

                            <UploadDocumentText>
                                {Translate.t('SignUp.SignUpRENModalUploadButtonLabel')}
                            </UploadDocumentText>
                        </UploadButton>

                    </InputItemContainer>
                </ButtonMainContainer>

            );
        }

        if (coBrokeStatus === CoBrokeStatusEnum.Approved) {
            return (
                <div>
                    <ButtonsContainer
                        label={Translate.t('Search.SearchButtonViewDetails')}
                        onClick={goToProperty}
                    />
                </div>
            );
        }

        if (coBrokeStatus === CoBrokeStatusEnum.Pending) {
            return (
                <div>
                    <h4>
                        {Translate.t('Search.PendingApproval')}
                    </h4>
                </div>
            );
        }

        return (
            <ButtonsContainer
                label={(Translate.t('Search.DownloadTemplate'))}
                onClick={() => downloadTemplate()}
            />
        );
    };

    return (
        <Card>
            <NameContainer>
                {name}
            </NameContainer>
            <h4>
                {unitNumber}
            </h4>
            <h4>
                {statusText}
            </h4>
            <h4>
                {typeText}
            </h4>
            <h4>
                {coBrokeStatusText}
            </h4>
            <ButtonStatusContainer>
                {renderActionButtons()}
            </ButtonStatusContainer>
        </Card>
    );
};

const Card = styled.div`
    display: grid;
    align-items: center;
    width: 100%;
    grid-template-columns:  16.66% 16.66% 16.66% 16.66% 16.66% 16.66%;
    font-family: ${Fonts.primary};
    color: black;
    margin-bottom: 10px;
    padding: 20px;
    font-size: 14px;
    font-weight: normal;
    box-sizing: border-box;
    background: ${Colors.primaryLightest};
    border-radius: 12px;
`;

const ButtonStatusContainer = styled.div`
display: flex;
flex-direction: column;
gap: 10px; 
`;

const ButtonsContainer = styled(Button)`
    width: 100%;
`;

const ButtonMainContainer = styled.div`
    justify-content: center;
    align-self: center;
`;

const UploadIcon = styled(SVG)`
`;

const UploadButton = styled.button`
    width: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: white;
`;

const NameContainer = styled.h4`
    word-break: break-all;
    word-break: break-word;
`;

const InputItemContainer = styled.div`
    display: flex;
    align-items: center;
    min-height: 40px;
    max-width: 100%;
    border-radius: 8px;
    margin-bottom: 8px;
    background-color: #F2B47E;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: white;
`;

const UploadDocumentText = styled.p`
    margin-left: 20px;
`;

const mapStateToProps = (state: RootState) => ({
    isModalOpen: Selectors.propertySetIsCoBrokeUploadModalOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setUploadCoBrokeDocumentModalOpen: (state: boolean) => dispatch(Actions.setCoBrokeUploadModalOpen(state)),
    setTemplateDownload: (documentTemplate: string, propertyId: string) => dispatch(Actions.getPropertyCoBrokeDocumentTemplateAttempt({ documentTemplate, propertyId })),
    setSelectedPropertyId: (propertyId: string) => dispatch(Actions.setSelectedPropertyId(propertyId)),
    clearPropertyViewOfferSessions: () => dispatch(Actions.clearPropertyViewOfferSessions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyCard);
