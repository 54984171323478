import React, { FunctionComponent } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import { history } from 'redux/store';

import LoginScreen from 'containers/auth/Login';
import SignUpScreen from 'containers/auth/SignUp';
import DetailedSignUpScreen from 'containers/auth/DetailedSignUp';
import VerificationEmail from 'containers/auth/VerificationEmail';
import VerifySignUp from 'containers/auth/VerifySignUp';
import PropertyScreen from 'containers/property';
import HomeScreen from 'containers/home';
import PropertyDetailsScreen from 'containers/property/PropertyDetails';
import ErrorScreen from 'containers/error';
import UserProfileScreen from 'containers/user';
import Unsubscribe from 'containers/auth/Unsubscribe';

import PrivateRoute from './PrivateRoutes';

const PrivateBucket: FunctionComponent = () => {
    return (
        <Route path='/' element={<PrivateRoute />}>
            <Route index element={<HomeScreen />} />
            <Route path='/dashboard' element={<HomeScreen />} />

            <Route path='/propertyLists' element={<PropertyScreen />} />
            <Route path='/propertyDetails/:id' element={<PropertyDetailsScreen />} />

            <Route path='/userProfile' element={<UserProfileScreen />} />

            <Route path='/error' element={<ErrorScreen />} />

        </Route>
    );
};

const NavRoutes: FunctionComponent = (props) => {
    return (
        <Router history={history}>
            <Routes>
                <Route path='/login' element={<LoginScreen />} />
                <Route path='/signup' element={<SignUpScreen />} />
                <Route path='/detailedSignup' element={<DetailedSignUpScreen />} />
                <Route path='/verificationEmail' element={<VerificationEmail />} />
                <Route path='/verifyEmail/:uuid' element={<VerifySignUp />} />
                <Route path='/unsubscribe/:hash' element={<Unsubscribe />} />

                <Route path='*' element={<Navigate replace to='/' />} />
                {PrivateBucket(props)}
            </Routes>
        </Router>
    );
};

export default NavRoutes;
