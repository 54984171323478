import authSlice from 'redux/slices/auth';
import uiSlice from 'redux/slices/ui';
import userSlice from 'redux/slices/user';
import propertySlice from 'redux/slices/property';

export default {
    ...authSlice.actions,
    ...uiSlice.actions,
    ...userSlice.actions,
    ...propertySlice.actions,
};
