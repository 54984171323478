import { put, call, takeEvery } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';

import AuthGateway from 'api/Auth';

import Actions from 'redux/Actions';

import { UnsubscribePayload } from 'redux/slices/auth/types';
import { GatewayResponseStatus } from 'api/types/types';

import NavActions from 'lib/NavActions';

export default function* watchUnsubscribe(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authUnsubscribeAttempt', handleUnsubscribe, api);
}

function* handleUnsubscribe(api: AuthGateway, data: UnsubscribePayload) {
    const { hash } = data.payload;

    const response = yield* call([api, api.unsubscribe], { hash: encodeURIComponent(hash) });

    if (response.status === GatewayResponseStatus.Success) {
        // perform operation here
        if (!response.data) {
            yield put(Actions.authUnsubscribeFailure('Something went wrong. Please try again.'));
            return;
        }

        yield put(Actions.authUnsubscribeSuccess());
    } else {
        // handle error
        yield put(Actions.authUnsubscribeFailure(response.message));

        toast.error('Invalid user');
        NavActions.navToLogin();
    }
}
