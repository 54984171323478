import { PropertyStatus, PropertyTypeEnum } from '@dm/types';
import { IOption } from 'components/SelectionInput';

export const propertyTypeSelectionInputData: IOption[] = [{
    id: PropertyTypeEnum.Apartment,
    description: 'Apartment',
},
{
    id: PropertyTypeEnum.Landed,
    description: 'Landed',
}];

export const propertyStatusSelectionInputData: IOption[] = [
    {
        id: PropertyStatus.approved,
        description: 'Approved',
    },
    {
        id: PropertyStatus.tenanted,
        description: 'Tenanted',
    },
    {
        id: PropertyStatus.vacant,
        description: 'Vacant',
    },
];
