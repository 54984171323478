import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import { LoginParams, LoginResponse, IAuthGateway, SignupParams, VerifySignUpParams, UnsubscribeParams } from './AuthBase';

export default class AuthGateway extends IAuthGateway {
    async login(params: LoginParams): GatewayResponse<LoginResponse> {
        const response: ApiResponse<LoginResponse> = await this.api.post('auth/login', params);
        return this.process(response);
    }

    async signup(params: SignupParams): GatewayResponse<null> {
        const response: ApiResponse<null> = await this.api.post('auth/signup', params);
        return this.process(response);
    }

    async verifySignUp(params: VerifySignUpParams): GatewayResponse<null> {
        const response: ApiResponse<null> = await this.api.post(`/auth/verifyEmail/${params.id}`);
        return this.process(response);
    }

    async unsubscribe(params: UnsubscribeParams): GatewayResponse<null> {
        const response: ApiResponse<null> = await this.api.post(`/auth/unsubscribe/${params.hash}`);
        return this.process(response);
    }
}
