import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import Translate from 'lib/translate';
import PropertyGateway from 'api/Property';
import { GatewayResponseStatus } from 'api/types/types';
import { GetSubmitOfferActionPayload } from 'redux/slices/property/types';
import { toast } from 'react-toastify';

export default function* watchSetPropertySubmitOfferAttempt(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/setPropertySubmitOfferAttempt', handleSetPropertySubmitOfferAttempt, api);
}

function* handleSetPropertySubmitOfferAttempt(api: PropertyGateway, data: GetSubmitOfferActionPayload) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const { offerSessionId, price } = data.payload;

    const response = yield* call([api, api.setPropertySubmitOffers], { authToken, offerSessionId, price });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.setPropertySubmitOfferFailure(response.message));
        toast.error(Translate.t('PropertyRpDetails.PropertyRpOfferFailedTryAgain'));
        return;
    }

    yield put(Actions.setPropertySubmitOfferSuccess(response.data));
    toast.success(Translate.t('PropertyRpDetails.PropertyRpOfferSubmitted'));
}
