import { REAGetOfferResponse, REAGetPropertiesResponseWithIndex, REAGetPropertyDetailsResponse } from '@dm/types';
import { PropertyState } from 'redux/slices/property';

const getPropertyListingAttempting = (state: PropertyState): boolean => state.actions.getPropertyListing || false;
const getPropertyListingError = (state: PropertyState): string => state.error.getPropertyListing || '';

const getPropertyListing = (state: PropertyState): REAGetPropertiesResponseWithIndex => state.propertyListingData;

const getPropertyDetailsAttempting = (state: PropertyState): boolean => state.actions.getPropertyDetails || false;
const getPropertyDetailsError = (state: PropertyState): string => state.error.getPropertyDetails || '';

const getPropertyDetails = (state: PropertyState): REAGetPropertyDetailsResponse => state.propertyDetails || null;

const getCoBrokeUploadUrlAttempting = (state: PropertyState): boolean => state.actions.getCoBrokeUploadUrl || false;
const getCoBrokeUploadUrlError = (state: PropertyState): string => state.error.getCoBrokeUploadUrl || '';

const getCoBrokeUploadUrl = (state: PropertyState): string => state.coBrokeDocumentUploadURL || '';

const uploadCoBrokeDocumentAttempting = (state: PropertyState): boolean => state.actions.uploadCoBrokeDocument || false;
const uploadCoBrokeDocumentError = (state: PropertyState): string => state.error.uploadCoBrokeDocument || '';
const uploadCoBrokeDocument = (state: PropertyState): string => state.uploadCoBrokeDocumentComplete;

const getPropertyCoBrokeDocumentTemplateAttempting = (state: PropertyState): boolean => state.actions.getCoBrokeTemplate || false;
const getPropertyCoBrokeDocumentTemplateError = (state: PropertyState): string => state.error.getCoBrokeTemplate || '';
const getPropertyCoBrokeDocumentTemplate = (state: PropertyState): string => state.coBrokeDocumentTemplate;

const setIsCoBrokeUploadModalOpen = (state: PropertyState): boolean => state.coBrokeModal || false;

const getSelectedPropertyId = (state: PropertyState): string => state.selectedPropertyId || '';

const getPropertyViewOfferSessionsAttempting = (state: PropertyState): boolean => state.actions.getOfferSessions || false;
const getPropertyViewOfferSessionsError = (state: PropertyState): string => state.error.getOfferSessions || '';

const getPropertyViewOfferSessions = (state: PropertyState): REAGetOfferResponse[] | null => state.offerSessions || null;

const setPropertySetPropertySubmitOfferAttempting = (state: PropertyState): boolean => state.actions.setsubmitOffer || false;
const setPropertySetPropertySubmitOffersError = (state: PropertyState): string => state.error.setsubmitOffer || '';
const setPropertySetPropertySubmitOffers = (state: PropertyState): string => state.setsubmitOfferSuccess || '';
const setRequestKeyAttempt = (state: PropertyState): boolean => state.actions.setRequestKey || false;
const setRequestKeyFailure = (state: PropertyState): string => state.error.setRequestKey || '';
const setRequestKeySuccess = (state: PropertyState): string => state.requestKeyData;

export default {
    getPropertyListingAttempting,
    getPropertyListingError,

    getPropertyListing,

    getPropertyDetailsAttempting,
    getPropertyDetailsError,

    getPropertyDetails,

    getCoBrokeUploadUrlAttempting,
    getCoBrokeUploadUrlError,

    getCoBrokeUploadUrl,

    uploadCoBrokeDocumentAttempting,
    uploadCoBrokeDocumentError,
    uploadCoBrokeDocument,

    getPropertyCoBrokeDocumentTemplateAttempting,
    getPropertyCoBrokeDocumentTemplateError,
    getPropertyCoBrokeDocumentTemplate,

    setIsCoBrokeUploadModalOpen,

    getSelectedPropertyId,

    getPropertyViewOfferSessionsAttempting,
    getPropertyViewOfferSessionsError,
    getPropertyViewOfferSessions,

    setPropertySetPropertySubmitOfferAttempting,
    setPropertySetPropertySubmitOffersError,
    setPropertySetPropertySubmitOffers,
    setRequestKeyAttempt,
    setRequestKeyFailure,
    setRequestKeySuccess,
};
