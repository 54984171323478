import { goBack, push, replace } from 'redux-first-history';

import { store } from 'redux/store';

const navPush = (routeName: string) => {
    store.dispatch(push(routeName));
};

const navReplace = (routeName: string) => {
    store.dispatch(replace(routeName));
};

const navBack = (): void => {
    store.dispatch(goBack());
};

const navResetToLogin = (): void => navReplace('/login');

const navToHome = (): void => navPush('/');

const navToLogin = (): void => navPush('/login');
const navToSignUp = (): void => navPush('/signup');
const navToDetailedSignUp = (): void => navPush('/detailedSignup');
const navToVerificationEmail = ():void => navPush('/verificationEmail');

const navToPropertyList = (): void => navPush('/propertyLists');

const navToPropertyDetails = (propertyId: string): void => navPush(`/propertyDetails/${propertyId}`);

const navToErrorScreen = (): void => navPush('/error');

const navToUserProfile = ():void => navPush('/userProfile');

export default {
    navResetToLogin,

    navToHome,
    navBack,

    navToLogin,
    navToSignUp,
    navToDetailedSignUp,
    navToVerificationEmail,

    navToPropertyList,
    navToPropertyDetails,

    navToErrorScreen,

    navToUserProfile,
};
