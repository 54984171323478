import { FurnishingTypeEnum, LandTitleTypeEnum, PropertyStatus, PropertyTitleTypeEnum, PropertyTypeEnum, RaceEnum, REAGetOfferResponse, REAGetPropertiesResponseWithIndex, REAGetPropertyDetailsResponse, TenureTypeEnum } from '@dm/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PropertyReduxState, GetPropertyListingActionPayload, UploadCoBrokeDocumentActionPayload, GetCoBrokeUploadUrlActionPayload, GetCoBrokeDocumentTemplateActionPayload, GetViewOfferSessionsActionPayload, GetSubmitOfferActionPayload, SetRequestKeyActionPayload } from './types';

const initialState: PropertyReduxState = {
    actions: {
        getPropertyListing: false,
        getPropertyDetails: false,
        getCoBrokeUploadUrl: false,
        uploadCoBrokeDocument: false,
        getCoBrokeTemplate: false,
        getOfferSessions: false,
        setsubmitOffer: false,
        setRequestKey: false,
    },
    propertyListingData: {
        index: -1,
        maxIndex: 0,
        data: [],
    },
    propertyDetails: {
        unitNo: '',
        id: '',
        name: '',
        location: {
            long: 0,
            lat: 0,
            address1: '',
            address2: '',
            postcode: '',
            city: '',
            state: '',
            country: '',
        },
        details: {
            type: PropertyTypeEnum.Apartment,
            titleType: PropertyTitleTypeEnum.Individual,
            buildUpSize: 0,
            occupancy: '',
            landTitle: LandTitleTypeEnum.Commercial,
            tenure: TenureTypeEnum.FreeHold,
            furnishing: FurnishingTypeEnum.Fully,
            facingDirection: '',
            preferredTenant: [],
            unitType: '',
            storey: 0,
            builtUpDimension: '',
            buildUpPricePerSqft: 0,
        },
        images: [],
        description: '',
        status: PropertyStatus.pendingApproval,

    },
    coBrokeDocumentUploadURL: '',
    coBrokeDocumentTemplate: '',
    uploadCoBrokeDocumentComplete: '',
    coBrokeModal: false,

    selectedPropertyId: '',
    offerSessions: null,
    setsubmitOfferSuccess: '',
    requestKeyData: '',

    error: {
        getPropertyListing: '',
        getPropertyDetails: '',
        getCoBrokeUploadUrl: '',
        uploadCoBrokeDocument: '',
        getCoBrokeTemplate: '',
        getOfferSessions: '',
        setsubmitOffer: '',

        setRequestKey: '',
    },
};

const propertySlice = createSlice({
    name: 'property',
    initialState,
    reducers: {
        getPropertyListingAttempt: (state, _action: GetPropertyListingActionPayload) => {
            state.actions.getPropertyListing = true;
            state.error.getPropertyListing = '';
        },
        getPropertyListingSuccess: (state, action: PayloadAction<REAGetPropertiesResponseWithIndex>) => {
            state.actions.getPropertyListing = false;
            state.error.getPropertyListing = '';

            state.propertyListingData = action.payload;
        },
        getPropertyListingFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPropertyListing = false;
            if (action.payload) {
                state.error.getPropertyListing = action.payload;
            }
        },

        getPropertyDetailsAttempt: (state, _action: PayloadAction<string>) => {
            state.actions.getPropertyDetails = true;
            state.error.getPropertyDetails = '';
        },
        getPropertyDetailsSuccess: (state, action: PayloadAction<REAGetPropertyDetailsResponse>) => {
            state.actions.getPropertyDetails = false;
            state.error.getPropertyDetails = '';

            state.propertyDetails = action.payload;
        },
        getPropertyDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPropertyDetails = false;
            if (action.payload) {
                state.error.getPropertyDetails = action.payload;
            }
        },
        getCoBrokeUploadUrlAttempt: (state, _action: GetCoBrokeUploadUrlActionPayload) => {
            state.actions.getCoBrokeUploadUrl = true;
            state.error.getCoBrokeUploadUrl = '';
        },
        getCoBrokeUploadUrlSuccess: (state, action: PayloadAction<string>) => {
            state.actions.getCoBrokeUploadUrl = false;
            state.error.getCoBrokeUploadUrl = '';

            state.coBrokeDocumentUploadURL = action.payload;
        },
        getCoBrokeUploadUrlFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getCoBrokeUploadUrl = false;
            if (action.payload) {
                state.error.getCoBrokeUploadUrl = action.payload;
            }
        },

        uploadCoBrokeDocumentAttempt: (state, _action: UploadCoBrokeDocumentActionPayload) => {
            state.actions.uploadCoBrokeDocument = true;
            state.error.uploadCoBrokeDocument = '';
        },
        uploadCoBrokeDocumentSuccess: (state, action: PayloadAction<string>) => {
            state.actions.uploadCoBrokeDocument = false;
            state.error.uploadCoBrokeDocument = '';

            state.uploadCoBrokeDocumentComplete = action.payload;
        },
        uploadCoBrokeDocumentFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.uploadCoBrokeDocument = false;
            if (action.payload) {
                state.error.uploadCoBrokeDocument = action.payload;
            }
        },

        getPropertyCoBrokeDocumentTemplateAttempt: (state, _action: GetCoBrokeDocumentTemplateActionPayload) => {
            state.actions.getCoBrokeTemplate = true;
            state.error.getCoBrokeTemplate = '';
        },
        getPropertyCoBrokeDocumentTemplateSuccess: (state, action: PayloadAction<string>) => {
            state.actions.getCoBrokeTemplate = false;
            state.error.getCoBrokeTemplate = '';

            state.coBrokeDocumentTemplate = action.payload;
        },
        getPropertyCoBrokeDocumentTemplateFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getCoBrokeTemplate = false;
            if (action.payload) {
                state.error.getCoBrokeTemplate = action.payload;
            }
        },

        setCoBrokeUploadModalOpen: (state, action: PayloadAction<boolean>) => {
            state.coBrokeModal = action.payload;
        },

        setSelectedPropertyId: (state, action: PayloadAction<string>) => {
            state.selectedPropertyId = action.payload;
        },

        getPropertyViewOfferSessionsAttempt: (state, _action: GetViewOfferSessionsActionPayload) => {
            state.actions.getOfferSessions = true;
            state.error.getOfferSessions = '';
        },
        getPropertyViewOfferSessionsSuccess: (state, action: PayloadAction<REAGetOfferResponse[] | null>) => {
            state.actions.getOfferSessions = false;
            state.error.getOfferSessions = '';
            state.offerSessions = action.payload;
        },
        getPropertyViewOfferSessionsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getOfferSessions = false;
            if (action.payload) {
                state.error.getOfferSessions = action.payload;
            }
        },

        clearPropertyViewOfferSessions: (state) => {
            state.offerSessions = null;
        },

        setPropertySubmitOfferAttempt: (state, _action: GetSubmitOfferActionPayload) => {
            state.actions.setsubmitOffer = true;
            state.error.setsubmitOffer = '';
        },
        setPropertySubmitOfferSuccess: (state, action: PayloadAction<string>) => {
            state.actions.setsubmitOffer = false;
            state.error.setsubmitOffer = '';
            state.setsubmitOfferSuccess = action.payload;
        },
        setPropertySubmitOfferFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setsubmitOffer = false;
            if (action.payload) {
                state.error.setsubmitOffer = action.payload;
            }
        },

        setRequestKeyAttempt: (state, _action: SetRequestKeyActionPayload) => {
            state.actions.setRequestKey = true;
            state.error.setRequestKey = '';
        },
        setRequestKeySuccess: (state, action: PayloadAction<string>) => {
            state.actions.setRequestKey = false;
            state.error.setRequestKey = '';

            state.requestKeyData = action.payload;
        },
        setRequestKeyFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setRequestKey = false;
            if (action.payload) {
                state.error.setRequestKey = action.payload;
            }
        },

    },
});

export type PropertyState = typeof initialState;

export default {
    actions: propertySlice.actions,
    reducers: propertySlice.reducer,
};
