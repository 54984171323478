import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import PropertyGateway from 'api/Property';
import { GatewayResponseStatus } from 'api/types/types';
import { GetCoBrokeUploadUrlActionPayload } from 'redux/slices/property/types';

export default function* watchGetCoBrokeUploadUrl(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/getCoBrokeUploadUrlAttempt', handleGetCoBrokeUploadUrl, api);
}

function* handleGetCoBrokeUploadUrl(api: PropertyGateway, data: GetCoBrokeUploadUrlActionPayload) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const { category, id, name, extension, document } = data.payload;

    const response = yield* call([api, api.getCoBrokeUploadUrl], { category, id, name, extension, authToken });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getCoBrokeUploadUrlFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.getCoBrokeUploadUrlSuccess(response.data));
        let fileType = '';

        if (extension === '.pdf') fileType = 'pdf';
        else fileType = 'image';
        yield put(Actions.uploadCoBrokeDocumentAttempt({ preSignedUrl: response.data, document, docType: fileType }));
    }
}
