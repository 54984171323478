import React, { FunctionComponent, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import { Oval } from 'react-loader-spinner';

import { BreadCrumb, Colors, Fonts, SearchBar } from '@dm/bigfish';
import { PropertyStatus, PropertyTypeEnum, REAGetPropertiesResponse, REAGetPropertiesResponseWithIndex } from '@dm/types';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import NavActions from 'lib/NavActions';
import Translate from 'lib/translate';

import { propertyTypeSelectionInputData, propertyStatusSelectionInputData } from 'entities/property';

import MainContainer from 'components/MainContainer';
import CustomPaginator from 'components/CustomPaginator';
import SelectionInput from 'components/SelectionInput';

import PropertyCard from './components/PropertyCard';
import CoBrokeDocumentModal from './components/CoBrokeDocumentModal';

interface PropertyProps {
    propertyListingData: REAGetPropertiesResponseWithIndex;
    getPropertyListingLoading: boolean;
    downloadTemplateLoading: boolean;
    getPropertyListingError: string;
    getPropertyListing: (propertyName: string, propertyType: PropertyTypeEnum, propertyStatus: PropertyStatus, index: number) => void;
}

const Property: FunctionComponent<PropertyProps> = (props: PropertyProps) => {
    const {
        propertyListingData,
        getPropertyListingLoading,
        downloadTemplateLoading,
        getPropertyListingError,
        getPropertyListing,
    } = props;

    const [currentPage, setCurrentPage] = useState(0);
    const [maxPages, setMaxPages] = useState(1);

    const [searchedPropertyName, setSearchedPropertyName] = useState('');
    const [selectedPropertyType, setSelectedPropertyType] = useState<PropertyTypeEnum>(PropertyTypeEnum.Apartment);
    const [selectedPropertyStatus, setSelectedPropertyStatus] = useState<PropertyStatus>(PropertyStatus.approved);
    const [propertyList, setPropertyList] = useState<REAGetPropertiesResponse[]>([]);

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('BreadCrumb.BreadCrumbDashboard'),
                onClick: () => { NavActions.navToHome(); },
            },
            {
                label: Translate.t('Search.SearchBcSearch'),
                onClick: () => { return false; },
            },
            {
                label: Translate.t('Search.SearchBcPropertiesResult'),
                onClick: () => { return false; },
            },
        ],
    );

    useEffect(() => {
        getPropertyListing(searchedPropertyName, selectedPropertyType, selectedPropertyStatus, currentPage + 1);
    }, [selectedPropertyType, selectedPropertyStatus, currentPage]);

    useEffect(() => {
        const { maxIndex, data } = propertyListingData;
        setMaxPages(maxIndex);
        setPropertyList(data);
    }, [propertyListingData]);

    const debouncedGetPropertyListing = debounce(getPropertyListing, 2000, { leading: false });

    const debounceCallBack = useCallback((
        propertyName: string,
        propertyStatus: PropertyStatus,
        propertyType: PropertyTypeEnum,
        index: number,
    ) => debouncedGetPropertyListing(propertyName, propertyType, propertyStatus, index), []);

    const searchNameChangeHandler = (value: string) => {
        setSearchedPropertyName(value);
        debounceCallBack(value, selectedPropertyStatus, selectedPropertyType, currentPage + 1);
    };

    const renderPropertyCards = () => {
        if (getPropertyListingLoading || downloadTemplateLoading) {
            return (
                <LoadingContainer>
                    <Oval
                        height={150}
                        width={150}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </LoadingContainer>
            );
        }

        if (getPropertyListingError) {
            return (
                <NoDataCard>
                    <div
                        style={{
                            color: Colors.danger,
                        }}
                    >
                        {getPropertyListingError}
                    </div>
                </NoDataCard>
            );
        }

        if (!propertyList.length) {
            return (
                <NoDataCard>
                    {Translate.t('Search.SearchNoProperties')}
                </NoDataCard>
            );
        }

        return (
            <div>
                {propertyList.map(item => {
                    const {
                        unitNo,
                        propertyId,
                        name,
                        images,
                        status,
                        coBrokeStatus,
                        type,
                    } = item;

                    return (
                        <div
                            key={propertyId}
                        >
                            {(status === PropertyStatus.approved) && (
                                <PropertyCard
                                    propertyId={propertyId}
                                    name={name}
                                    images={images}
                                    status={status}
                                    coBrokeStatus={coBrokeStatus}
                                    type={type}
                                    unitNumber={unitNo}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <>
            <BreadCrumb onBackPressed={() => NavActions.navBack()} data={breadCrumb} />
            <MainContainer>
                <InnerMainContainer>

                    <SearchBarContainer>
                        <StyledSearchBar
                            title='Property'
                            value={searchedPropertyName}
                            onChangeText={(e) => searchNameChangeHandler(e.target.value)}
                        />
                    </SearchBarContainer>

                    <FiltersContainer>
                        <H3MainContainer>
                            {Translate.t('Search.SearchFiltersTitle')}
                        </H3MainContainer>

                        <SelectionInputMainGapContainer>

                            <SelectionInputMainContainer>
                                <SelectionInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelPropType')}
                                    data={propertyTypeSelectionInputData}
                                    currentValue={selectedPropertyType}
                                    onChangeSelection={(e) => setSelectedPropertyType(Number(e.target.value))}
                                />
                            </SelectionInputMainContainer>

                            <SelectionInputMainContainer>
                                <SelectionInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelPropStatus')}
                                    data={propertyStatusSelectionInputData}
                                    currentValue={selectedPropertyStatus}
                                    onChangeSelection={(e) => setSelectedPropertyStatus(Number(e.target.value))}
                                />
                            </SelectionInputMainContainer>

                        </SelectionInputMainGapContainer>
                    </FiltersContainer>
                    <DisplayMainContainer>

                        <OuterMainContainer>
                            <OuterPaginatorContainer>
                                <InnerPaginatorContainer>
                                    <CustomPaginator
                                        pageCount={maxPages}
                                        forcePage={currentPage}
                                        onPageChange={(e) => setCurrentPage(e)}
                                    />
                                </InnerPaginatorContainer>
                            </OuterPaginatorContainer>

                            <HeadersMainContainer>
                                <HeadersContainer>
                                    <h4>
                                        {Translate.t('Search.SearchHeaderName')}
                                    </h4>
                                    <h4>
                                        {Translate.t('Search.SearchHeaderUnitName')}
                                    </h4>
                                    <h4>
                                        {Translate.t('Search.SearchHeaderStatus')}
                                    </h4>
                                    <h4>
                                        {Translate.t('Search.SearchHeaderType')}
                                    </h4>
                                    <h4>
                                        {Translate.t('Search.SearchHeaderCoBrokeStatus')}
                                    </h4>
                                    <h4>
                                        {Translate.t('Search.SearchHeaderActions')}
                                    </h4>
                                </HeadersContainer>

                                {renderPropertyCards()}
                            </HeadersMainContainer>

                            <OuterPaginatorContainer>
                                <InnerPaginatorContainer>
                                    <CustomPaginator
                                        pageCount={maxPages}
                                        forcePage={currentPage}
                                        onPageChange={(e) => setCurrentPage(e)}
                                    />
                                </InnerPaginatorContainer>
                            </OuterPaginatorContainer>
                        </OuterMainContainer>

                        {/* <div
                            style={{
                                backgroundColor: 'red',
                                width: '30%',
                            }}
                        >
                            Recent Events here!

                            keeping this here for recent events in the future
                        </div> */}

                    </DisplayMainContainer>

                </InnerMainContainer>

                <CoBrokeDocumentModal />
            </MainContainer>
        </>
    );
};

const DisplayMainContainer = styled.h3`
    display: flex;
`;

const H3MainContainer = styled.h3`
    margin-bottom: 20px;
`;

const SelectionInputMainGapContainer = styled.div`
    display: flex;
    gap: 20px;
`;

const SelectionInputMainContainer = styled.div`
    width: 20%;
`;

const HeadersMainContainer = styled.div`
    margin-top : 20px;
    margin-bottom: 20px;
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;    
`;

const OuterMainContainer = styled.div`
    width: 70%;
    display: flex;
    flex-direction: column;    
`;

const NoDataCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-family: ${Fonts.primary};
    color: black;
    margin-bottom: 10px;
    padding: 20px;
    font-size: 16px;
    font-weight: bold;
    box-sizing: border-box;
    background: ${Colors.primaryLightest};
    border-radius: 12px;
`;

const HeadersContainer = styled.div`
    display: grid;
    align-items: center;
    width: 100%;
    /* grid-template-columns: 20% 20% 20% 20% 20%; */
    grid-template-columns:  16.66% 16.66% 16.66% 16.66% 16.66% 16.66%;
    /* grid-template-columns: 250px repeat(6, 1fr); */
    font-family: ${Fonts.primary};
    color: #696969;
    margin-bottom: 10px;
    padding: 20px;
    font-size: 14px;
    font-weight: normal;
    box-sizing: border-box;
    background: ${Colors.white};
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const InnerMainContainer = styled.div`
    padding: 20px;
`;

const SearchBarContainer = styled.div`
    margin-top: 40px;
    margin-bottom: 40px;
`;

const InnerPaginatorContainer = styled.div`
    width: 40%;
`;

const OuterPaginatorContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const StyledSearchBar = styled(SearchBar)`
    height: 40px;
`;

const FiltersContainer = styled.div`
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    background: ${Colors.white};
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
`;

const mapStateToProps = (state: RootState) => ({
    getPropertyListingLoading: Selectors.propertyGetPropertyListingAttempting(state),
    getPropertyListingError: Selectors.propertyGetPropertyListingError(state),
    downloadTemplateLoading: Selectors.propertyGetPropertyCoBrokeDocumentTemplateAttempting(state),
    propertyListingData: Selectors.propertyGetPropertyListing(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getPropertyListing: (propertyName: string, propertyType: PropertyTypeEnum, propertyStatus: PropertyStatus, index: number) => dispatch(Actions.getPropertyListingAttempt({ propertyName, propertyStatus, propertyType, index })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Property);
