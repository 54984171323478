const dormeoBankDetails = {
    bankName: process.env.REACT_APP_BANK_NAME,
    bankNumber: process.env.REACT_APP_BANK_NUMBER,
    accountName: process.env.REACT_APP_BANK_NUMBER,
    mapApiKey: process.env.REACT_APP_MAPAPI_KEY,
};

const useMockApi = false;

export default {
    useMockApi,

    baseUrl: process.env.REACT_APP_BASE_URL,
    dormeoBankDetails,
};
