import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import PropertyGateway from 'api/Property';
import { GatewayResponseStatus } from 'api/types/types';

export default function* watchGetPropertyDetails(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/getPropertyDetailsAttempt', handleGetPropertyDetails, api);
}

function* handleGetPropertyDetails(api: PropertyGateway, data: PayloadAction<string>) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const response = yield* call([api, api.getPropertyDetails], { authToken, id: data.payload });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getPropertyDetailsFailure(response.message));
        return;
    }

    yield put(Actions.getPropertyDetailsSuccess(response.data));
}
