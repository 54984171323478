export default {
    Ui: {
        UiCareline: 'Contact Our Careline',
        UiBack: 'Back',
        UiCompanyName: 'Dormeo Homes',
        UiSearchTitle: 'buy',
        UiProfileButtonTitle: 'login',
    },

    Header: {
        HeaderMyProfile: 'My Profile',
        HeaderMyProperties: 'My Properties',
        HeaderSettings: 'Settings',
        HeaderHelpCenter: 'Help Center',
        HeaderLogOut: 'Log Out',
    },

    BreadCrumb: {
        BreadCrumbDashboard: 'Dashboard',
        BreadCrumbWelcomeBack: 'Welcome Back',
    },

    Search: {
        SearchBcSearch: 'Search',
        SearchBcPropertiesResult: 'Properties - Results',
        SearchNoProperties: 'No Properties Found',
        SearchFiltersTitle: 'All Property Listings',
        SearchHeaderName: 'Name',
        SearchHeaderUnitName: 'Unit Name',
        SearchHeaderStatus: 'Status',
        SearchHeaderType: 'Type',
        SearchHeaderCoBrokeStatus: 'Co Broke Status',
        SearchHeaderActions: 'Actions',

        SearchPropertyApproved: 'Approved',
        SearchPropertyRejected: 'Rejected',
        SearchPropertyDeactivated: 'Deactivated',
        SearchPropertyDraftProperty: 'Draft Property',
        SearchPropertyPendingApproval: 'Pending Approval',
        SearchPropertyTenanted: 'Tenanted',
        SearchPropertyVacant: 'Vacant',

        SearchPropertyApartment: 'Apartment',
        SearchPropertyLanded: 'Landed',

        SearchButtonViewDetails: 'View Details',
        SearchButtonApprove: 'Approve',
        SearchButtonReject: 'Reject',
        SearchButtonClose: 'Close',

        DocumentUploaded: 'Document Uploaded',
        CoBrokeModalTitle: 'Upload Co Broke Document',
        DownloadTemplate: 'Download Template',
        PendingApproval: 'pending approval',
        DownloadFailed: 'download failed please try again',
        UploadedSuccessfully: 'uploaded successfully',
        Filetypenotsupported: 'Filetype not supported',

    },

    Profile: {
        ProfileBcMyProfile: 'My Profile',
        ProfileDescription: 'Please fill in the details for the following categories to complete your account setup.',
        ProfileSignupTitle: 'Profile Details',
        ProfileFirstName: 'First Name',
        ProfileLastName: 'Last Name',
        ProfilePhoneNumber: 'Phone Number',
        ProfileRenNumber: 'REN Number',
        ProfileCompanyName: 'Company Name',
        ProfileAddress1: 'Address 1',
        ProfileAddress2: 'Address 2',
        ProfileCity: 'City',
        ProfileCountry: 'Country',
        ProfileState: 'State',
        ProfilePostcode: 'Postcode',
        ProfileWelcomeModalTitle: 'Welcome to Dormeo!',
        ProfileWelcomeModalDesc1: 'Thank you for signing up to Dormeo Homes, the leading property management service website.',
        ProfileWelcomeModalDesc2: 'Finish your account setup by filling your personal details to get started.',
        ProfileWelcomeModalContinueButton: 'Continue',
        ProfileSubmitButton: 'Submit',
        ProfileEditProfileButton: 'Edit Profile',
        ProfileViewRENDocButton: 'View REN Document',
        ProfileUploadRENDocButton: 'Upload REN Document',
        ProfileRENDocTitle: 'REN Document',
    },

    User: {
        UserBcUser: 'User',
        UserBcProfile: 'Profile',
    },

    Login: {
        LoginLabel: 'Login',
        LoginReaLogin: 'REA Login',
        LoginEmail: 'Your email address',
        LoginPlaceholder: 'eg. example123@gmail.com',
        LoginPlaceholderPassword: 'enter a password',
        LoginRememberMe: 'Remember me',
        LoginPassword: 'Your password',
        LoginHaveYou: 'Have you',
        LoginForgotPassword: 'forgotten your password?',
        LoginNewTo: 'New to Dormeo?',
        LoginJoinNow: 'Join now',
        LoginErrorEmail: 'Please insert a valid email address',
        LoginBcLogin: 'Login',
        LoginBcReaLogin: 'REA Login',
        LoginEmptyInputError: 'Please fill in all the inputs',
    },

    SignUp: {
        SignUpTitle: 'REA Sign Up',
        SignUpLabel: 'Signup',
        SignUpReaSignup: 'REA Signup',
        SignUpToastError: 'Password and confirm password must be match',
        SignUpErrorEmail: 'Enter a valid email address',
        SignUpEmail: 'Your email address',
        SignUpPlaceholderEmail: 'eg. example123@gmail.com',
        SignUpPlaceholderPassword: 'enter a password',
        SignUpPassword: 'Your password',
        SignUpConfirmPassword: 'Confirm your password',
        SignUpAlreadyUser: 'Already a user?',
        SignUpLogin: 'Login',
        SignUpBcSignUp: 'Signup',
        SignUpBcReaSignUp: 'REA Signup',
        SignUpPasswordStrengthText: 'Password must contain atleast 1 upper case, 1 lower case, 1 symbol and atleast 5 characters long',
        SignUpRENModalTitle: 'Upload REN Document',
        SignUpRENModalInputLabel: 'Document Name',
        SignUpRENModalUploadButtonLabel: 'Upload Document',
        SignUpRENModalUploadDisclaimer: 'Note: only accepts .pdf, .jpg, .jpeg and .png file types',
        SignUpRENModalNoDocumentNameError: 'Please provide a document name',
        SignUpRENDocumentUploadedMessage: 'REN Document Uploaded',
    },

    VerifySignUp: {
        VerifySignUpLabel: 'Okay',
        VerifySignUpTitleSuccess: 'Your Account Has Been Verified!',
        VerifySignUpDescSuccess: 'Your REA account has been verified, please login your REA account to begin using Dormeo Homes services.',
        VerifySignUpTitleFailed: 'Your Account Has Not Been Verified!',
        VerifySignUpDescFailed: 'Your REA account has not been verified, please check your email',
        VerifyEmailBcSignUp: 'Signup',
        VerifyEmailBcReaSignUp: 'REA Signup',
        VerifyEmailBcVerificationEmail: 'Email Verification',
    },

    VerificationEmail: {
        VerificationEmailLabel: 'Okay, Got It.',
        VerificationEmailTitle: 'Email Verification',
        VerificationEmailDesc: 'Your account requires an email verification, please check your email.',
        VerificationEmailBcSignUp: 'Signup',
        VerificationEmailBcReaSignUp: 'REA Signup',
        VerificationEmailBcVerificationEmail: 'Email Verification',
    },

    Modules: {
        ModulesStats: 'stats',
        ModulesModules: 'modules',

        ModulesPendingActivities: 'Pending Activities',
        ModulesPendingActivitiesButton: 'Go to Pending Activities page',
        ModulesPendingActivitiesEmpty: 'You have no pending activities',
        ModulesPendingApprovals: 'Pending Approvals',
        ModulesPendingApprovalsButton: 'Go to Approvals page',
        ModulesPendingApprovalsEmpty: 'You have no pending approvals',

        ModulesSearch: 'Search',
        ModulesSearchDesc: 'Search for tenants & property listings',
        ModulesReports: 'Reports',
        ModulesReportsDesc: 'Check detailed reports on listings',
        ModulesCurrentProperties: 'Current Properties',
        ModulesCurrentPropertiesDesc: 'Check current properties details',
        ModulesTenants: 'Tenants',
        ModulesTenantsDesc: 'Manage tenants details',
        ModulesBids: 'Bids',
        ModulesBidsDesc: 'View current bidding details',
        ModulesCoBrooking: 'Co-Brookings',
        ModulesCoBrookingDesc: 'View current co-brooking details',
    },

    Modal: {
        ModulesCreateTitle: 'What are you creating?',
        ModulesCreateDesc: 'Please choose the correct option to start creation process.',
        ModulesCreateBtnOwner: 'Owner Account',
        ModulesCreateBtnListing: 'Property Listing',
    },

    CreateNew: {
        CreateNewBcDashboard: 'Dashboard',
        CreateNewBcCreate: 'Create',
        CreateNewBcOwner: 'Owner Account Setup Process',
        CreateNewBcError1: 'Please fill and complete the attach properties field and acc details!',
        CreateNewBcDesc: 'Please ensure each step of the process is filled in correctly to ensure no errors.',
        CreateNewStep1: 'step 1:',
        CreateNewOwnerDetails: 'Owner’s Details',
        CreateNewDesc: 'Please fill in the details for the following categories to complete your account setup.',
        CreateNewTitle: 'Personal Details',
        CreateNewLabelTitle: 'Title',
        CreateNewLabelGender: 'Gender',
        CreateNewLabelCPAddress1: 'Corresponding Address 1',
        CreateNewLabelCPAddress2: 'Corresponding Address 2',
        CreateNewLabelFirstName: 'First Name',
        CreateNewLabelLastName: 'Last Name',
        CreateNewLabelIDNo: 'ID No',
        CreateNewLabelCity: 'City/ Town',
        CreateNewLabelPassport: 'Passport No',
        CreateNewLabelMobile: 'Mobile No',
        CreateNewLabelPostcode: 'Postcode',
        CreateNewLabelCountryOrigin: 'Country of Origin',
        CreateNewLabelHouseTel: 'House Tel.',
        CreateNewLabelState: 'State',
        CreateNewLabelRace: 'Race',
        CreateNewLabelCountryResidence: 'Country of Residence',
        CreateNewLabelCountry: 'Country',
        CreateNewLabelDOB: 'Date of Birth',
        CreateNewLabelBankType: 'Bank Type',
        CreateNewLabelCurrency: 'Currency',
        CreateNewLabelBranchAddress: 'Bank Address',
        CreateNewLabelBranchName: 'Bank Name',
        CreateNewLabelBranchAccNo: 'Bank Acc No',
        CreateNewLabelSwiftCode: 'Swift Code',
        CreateNewLabelCompanyName: 'Company Name',
        CreateNewLabelDesignation: 'Company Designation',
        CreateNewLabelOfficeNo: 'Office No.',
        CreateNewLabelFullName: 'Full Name',
        CreateNewLabelRelationship: 'Relationship',
        CreateNewLabelEmail: 'Email',
        CreateNewErrorEmail: 'Please insert a valid email address',
        CreateNewErrorPass: 'Please insert a valid password',
        CreateNewErrorPassConfirm: 'Password confirmation does not match with password',
        CreateNewFinancialDetails: 'Financial Details',
        CreateNewCompDetails: 'Company Details',
        CreateNewEmergencyDetails: 'Emergency Contact Details',
        CreateNewStep2: 'step 2:',
        CreateNewAttachProp: 'Attach Properties',
        CreateNewNewProj: 'new project',
        CreateNewNoPropFound: 'No Property Found',
        CreateNewStep3: 'step 3:',
        CreateNewAccDetails: 'Account Details',
        CreateNewBtnSubmit: 'Submit',
        CreateNewBtnClear: 'Clear',
        CreateNewBtnConfirm: 'Confirm',
    },

    OwnersReport: {
        OwnersReportBcDashboard: 'Dashboard',
        OwnersReportBcOwners: 'Owners',
        OwnersReportBcOwnerDetails: 'Owner Details',
        OwnersReportFilterTitle: 'Owner Accounts Results',
        OwnersReportFilterCountry: 'Country',
        OwnersReportFilterLocation: 'Location',
        OwnersReportFilterStatus: 'Status',
        OwnersReportFilterProperties: 'Properties',
        OwnersReportFilterFinance: 'Finance',
        OwnersReportFilterDefault: 'Default',
        OwnersReportSaveSearch: 'Save search',
        OwnersReportHeaderName: 'Name',
        OwnersReportHeaderPackages: 'Packages',
        OwnersReportHeaderAge: 'Age',
        OwnersReportHeaderGender: 'Gender',
        OwnersReportHeaderLocation: 'Location',
        OwnersReportHeaderProp: 'Properties',
        OwnersReportHeaderMonthlyExpense: 'Monthly Expense',
        OwnersReportError1: 'No records found',
        OwnersReportError2: 'If using a custom view, try adjusting the filters.',
        OwnersReportError3: 'Otherwise, create some data!',
        OwnersReportError4: 'Something error',
        OwnersReportError5: 'Please contact system administrator for details.',
        OwnersReportError6: 'Otherwise, please try again!',
        OwnersReportRecentViewed: 'Recently Viewed',
        OwnersReportMoreLocations: 'More Locations',
        OwnersReportButtonMore: 'More',
        OwnersReportManagedProperties: 'Managed Properties',
    },

    PropertyReport: {
        PropertyReportBcDashboard: 'Dashboard',
        PropertyReportBcProperty: 'Property Listings',
        PropertyReportFilterTitle: 'All Property Listings',
        PropertyReportFilterBuildingTypes: 'All Building Types',
        PropertyReportFilterStatus: 'Status',
        PropertyReportFilterTypes: 'Types',
        PropertyReportFilterSize: 'Built-up Size',
        PropertyReportFilterConfig: 'Configurations',
        PropertyReportFilterProp: 'Show Properties',
        PropertyReportFilterDefault: 'Default',
        PropertyReportSaveSearch: 'Save search',
        PropertyReportHeaderName: 'Name',
        PropertyReportHeaderStatus: 'Status',
        PropertyReportHeaderSize: 'Size',
        PropertyReportHeaderType: 'Type',
        PropertyReportHeaderConfig: 'Configuration',
        PropertyReportHeaderOwner: 'Owner',
        PropertyReportHeaderLocation: 'Location',
        PropertyReportError1: 'No records found',
        PropertyReportError2: 'If using a custom view, try adjusting the filters.',
        PropertyReportError3: 'Otherwise, create some data!',
        PropertyReportError4: 'Something error',
        PropertyReportError5: 'Please contact system administrator for details.',
        PropertyReportError6: 'Otherwise, please try again!',
        PropertyReportRecentViewed: 'Recently Viewed',
        PropertyReportMoreLocations: 'More Locations',
        PropertyReportButtonMore: 'More',
    },

    PropertyRpDetails: {
        PropertyRpDetailsBcDashboard: 'Dashboard',
        PropertyRpDetailsBcPropertyListing: 'Property Listings',
        PropertyRpDetailsTitlePropDetails: 'Property Details',
        PropertyRpDetailsTitleRoomDetails: 'Room Details',
        PropertyRpDetailsTitleAdminControls: 'Admin Controls',
        PropertyRpDetailsBtnApprove: 'Approve Property',
        PropertyRpDetailsBtnReject: 'Reject Property',
        PropertyRpDetailsBtnContact: 'Contact Owner',
        PropertyRpDetailsLabelPropType: 'Property Type',
        PropertyRpDetailsLabelPropStatus: 'Property Status',
        PropertyRpDetailsLabelLandTitle: 'Land Title',
        PropertyRpDetailsLabelUnitType: 'Unit Type',
        PropertyRpDetailsLabelPropTitleType: 'Property Title Type',
        PropertyRpDetailsLabelTenure: 'Tenure',
        PropertyRpDetailsLabelStorey: 'Storey',
        PropertyRpDetailsLabelBtSize: 'Built-up Size',
        PropertyRpDetailsLabelBtPrice: 'Built-Up Price',
        PropertyRpDetailsLabelBtDim: 'Built-Up Dimension',
        PropertyRpDetailsLabelRefNo: 'Reference No.',
        PropertyRpDetailsLabelPostedDate: 'Posted Date',
        PropertyRpDetailsLabelFurnishing: 'Furnishing',
        PropertyRpDetailsLabelOccupancy: 'Occupancy',
        PropertyRpDetailsLabelFacingDirection: 'Facing Direction',
        PropertyRpDetailsErrorNoImagesFound: 'No images found',
        PropertyRpDetailsInstruction: 'Scroll horizontally to show other images',
        PropertyRpDetailsOfferText: 'Place an offer for this property?',
        PropertyRpOffer: 'Offer',
        PropertyRpSetOfferPrice: 'Set Offer Price',
        PropertyRpOfferNoSession: 'owner havent start the offer session.',
        PropertyRpSuccesSession: 'offer session will start at ',
        PropertyRpSuccesSessionEnd: 'and end at',
        PropertyRpOfferSubmitted: 'Offer Submitted',
        PropertyRpOfferFailedTryAgain: 'Offer failed try again',
        PropertyRpOfferNoOffer: 'No offer has been made before',
        PropertyRpPriceNot0: 'Offer cannot be set to 0',

        PropertyRpDetailsButtonLabelOffer: 'Offer',
        PropertyRpDetailsButtonLabelRequestKey: 'Request Key',
        PropertyRpDetailsSelectdate: 'Select a date',
        PropertyRpDetailsButtonLabelSetDate: 'Set date',
        PropertyRpNoLocation: ' no location information',
    },

    PropertyAmenities: {
        ItemTitleAmenities: 'Amenities',
        ItemTitleFurnishing: 'Furnishing',
        ItemTitleFacilities: 'Facilities',
        CollapsibleTitle: 'Property Amenities',
        CollapsiblePretext: 'Step 4:',

        TelevisionLabel: 'Television',
        CurtainLabel: 'Curtain',
        MattressLabel: 'Mattress',
        WashingMachineLabel: 'Washing Machine',
        HoodHubLabel: 'Hood & Hub',
        AirConditionerLabel: 'Air-Conditioner',
        WaterHeaterLabel: 'Water Heater',
        DiningTableLabel: 'Dining Table',
        WardrobeLabel: 'Wardrobe',
        KitchenCabinetLabel: 'Kitchen Cabinet',
        OvenLabel: 'Oven',
        RefrigeratorLabel: 'Refrigerator',
        SofaLabel: 'Sofa',
        MicrowaveLabel: 'Microwave',
        BedframeLabel: 'Bedframe',
        InternetLabel: 'Internet',

        CoveredParkingLabel: 'Covered Parking',
        GymnasiumLabel: 'Gymnasium',
        BasketballCourtLabel: 'Basketball Court',
        RestaurantLabel: 'Restaurant',
        DobiLabel: 'Dobi / Laundromat',
        NurseryLabel: 'Nursery',
        PlaygroundLabel: 'Playground',
        SaunaLabel: 'Sauna',
        SwimmingPoolLabel: 'Swimming Pool',
        TennisCourtLabel: 'Tennis Court',
        SecurityLabel: '24-Hour Security',
        MiniMartLabel: 'Mini Mart',
        SquashCourtLabel: 'Squash Court',
        BadmintonCourtLabel: 'Badminton Court',
        ElevatorLabel: 'Elevator',
    },

    PaymentsReport: {
        PaymentsRpBcDashboard: 'Dashboard',
        PaymentsRpBcPayments: 'Payments',
        PaymentsRpTitle: 'Payments',
        PaymentsRpHeaderID: 'ID',
        PaymentsRpHeaderName: 'Name',
        PaymentsRpHeaderCreatedAt: 'Created At',
        PaymentsRpHeaderTotalPrice: 'Total Price',
        PaymentsRpHeaderPackages: 'Packages',
        PaymentsRpHeaderStatus: 'Status',
        PaymentsRpHeaderActions: 'Actions',
        PaymentsRpActionButtonApprove: 'Approve',
        PaymentsRpActionButtonReject: 'Reject',
        PaymentsRpApproveLowerCase: 'approve',
        PaymentsRpRejectLowerCase: 'reject',
        PaymentsRpApproveRejectMsg1: 'Are you sure you want to',
        PaymentsRpApproveRejectMsg2: 'this payment?',
    },

    Error: {
        GeneralError: 'Sorry something went wrong please try again',
        ErrorGeneralErrorScreenBc: 'Error',
        ErrorGeneralErrorScreenText: 'Sorry, something went wrong.',
    },

    Unsubscribe: {
        UnsubscribeTitle: 'Unsubscribe',
        UnsubscribeDesc: 'Thank you, you have been successfully removed from this subscriber list and wont receive any further emails from us',
    },

    PropertyRequestKey: {
        PropertySetKeyRequestSuccess: 'Pending date for approval is  ',
        PropertySetKeyRequestFail: 'Please try again',
    },
};
