import React, { FunctionComponent, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Oval } from 'react-loader-spinner';

import { BreadCrumb, Button, Colors, LineInput } from '@dm/bigfish';
import { IREAUser, IUpdateREAUserInfo } from '@dm/types';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import Translate from 'lib/translate';
import NavActions from 'lib/NavActions';
import CountriesOptions from 'lib/CountriesOptions';

import MainContainer from 'components/MainContainer';
import styled from 'styled-components';
import SelectionInput from 'components/SelectionInput';
import Modal from 'components/Modal';
import RENDocumentModal from 'containers/auth/components/RENDocumentModal';

interface UserProfileProps {
    userInfo: IREAUser | null;

    updateUserInfoLoading: boolean;
    updateUserInfoError: string;

    updateUserInfo: (data: IUpdateREAUserInfo) => void;
    setUploadRENModalOpen: (state: boolean) => void;
    setCurrentPathName: (pathname: string) => void;
}

const UserProfile: FunctionComponent<UserProfileProps> = (props: UserProfileProps) => {
    const {
        userInfo,
        updateUserInfoLoading,
        updateUserInfoError,
        updateUserInfo,
        setUploadRENModalOpen,
        setCurrentPathName,
    } = props;

    const [isViewRENDocModalOpen, setIsViewRENDocModalOpen] = useState(false);

    const [stateFirstName, setStateFirstName] = useState('');
    const [stateLastName, setStateLastName] = useState('');
    const [statePhoneNumber, setStatePhoneNumber] = useState('');
    const [stateRenNumber, setStateRenNumber] = useState('');
    const [stateCompanyName, setStateCompanyName] = useState('');
    const [stateRenFileUrl, setStateRenFileUrl] = useState('');

    // Address
    const [stateAddress1, setStateAddress1] = useState('');
    const [stateAddress2, setStateAddress2] = useState('');
    const [stateCity, setStateCity] = useState('');
    const [statePostcode, setStatePostcode] = useState('');
    const [stateState, setStateState] = useState('');
    const [stateCountry, setStateCountry] = useState('1'); // 1 here bcs countries option list starts with 1 (Malaysia)

    const [isEditing, setIsEditing] = useState(false);

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('User.UserBcUser'),
                onClick: () => { return false; },
            },
            {
                label: Translate.t('User.UserBcProfile'),
                onClick: () => { return false; },
            },
        ],
    );

    const location = useLocation();

    useEffect(() => {
        if (userInfo) {
            const { firstName, lastName, phoneNumber, renNumber, companyName, address, renFileUrl } = userInfo;

            const address1 = address?.address1;
            const address2 = address?.address2;
            const city = address?.city;
            const country = address?.country;
            const postcode = address?.postcode;
            const state = address?.state;

            setStateFirstName(firstName);
            setStateLastName(lastName);
            setStatePhoneNumber(phoneNumber);
            setStateRenNumber(renNumber);
            setStateCompanyName(companyName);
            setStateRenFileUrl(renFileUrl[0]);

            setStateAddress1(address1);
            setStateAddress2(address2);
            setStateCity(city);
            setStateCountry(country);
            setStatePostcode(postcode);
            setStateState(state);

            setCurrentPathName(location.pathname);
        }
    }, [userInfo]);

    const submitClickHandler = () => {
        if (
            !stateFirstName
            || !stateLastName
            || !statePhoneNumber
            || !stateRenNumber
            || !stateCompanyName
            || !stateAddress1
            || !stateAddress2
            || !stateCity
            || !stateCountry
            || !stateState
            || !statePostcode
        ) {
            toast.error(Translate.t('Login.LoginEmptyInputError'));
            return;
        }

        const dataToSubmit: IUpdateREAUserInfo = {
            firstName: stateFirstName,
            lastName: stateLastName,
            phoneNumber: statePhoneNumber,
            renNumber: stateRenNumber,
            companyName: stateCompanyName,
            address: {
                address1: stateAddress1,
                address2: stateAddress2,
                city: stateCity,
                country: stateCountry,
                state: stateState,
                postcode: statePostcode,
            },
        };

        updateUserInfo(dataToSubmit);
        setIsEditing(false);
    };

    const renderRenDocumentModal = () => {
        return (
            <Modal
                show={isViewRENDocModalOpen}
                onClickClose={() => setIsViewRENDocModalOpen(false)}
                width='80%'
                closeCursor
            >
                <h1>
                    {Translate.t('Profile.ProfileRENDocTitle')}
                </h1>
                <div
                    style={{
                        width: '100%',
                        minHeight: '700px',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '20px',
                    }}
                >
                    <iframe
                        style={{
                            objectFit: 'cover',
                            width: '100%',
                        }}
                        title='REN Document'
                        src={stateRenFileUrl}
                    />
                </div>
            </Modal>
        );
    };

    const renderLoadingOverlay = () => {
        return (
            <Modal
                show={updateUserInfoLoading}
                width='50%'
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '20px',
                    }}
                >
                    <Oval
                        height={200}
                        width={200}
                        color={Colors.secondary}
                        secondaryColor={Colors.primary}
                    />
                </div>
            </Modal>
        );
    };

    const renderActionButton = () => {
        if (isEditing) {
            return (
                <Button
                    label={Translate.t('Profile.ProfileSubmitButton')}
                    onClick={submitClickHandler}
                />
            );
        }

        return (
            <Button
                label={Translate.t('Profile.ProfileEditProfileButton')}
                onClick={() => setIsEditing(!isEditing)}
            />
        );
    };

    return (
        <>
            <BreadCrumb onBackPressed={() => NavActions.navBack()} data={breadCrumb} />
            <MainContainer>
                <Container>
                    <InputsContainer>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '12px',
                                gap: '20px',
                            }}
                        >
                            <SectionTitle>
                                {Translate.t('Profile.ProfileSignupTitle')}
                            </SectionTitle>

                            <Button
                                label={Translate.t('Profile.ProfileViewRENDocButton')}
                                onClick={() => setIsViewRENDocModalOpen(true)}
                                style={{
                                    fontSize: '16px',
                                }}
                            />

                            {isEditing && (
                                <Button
                                    label={Translate.t('Profile.ProfileUploadRENDocButton')}
                                    onClick={() => setUploadRENModalOpen(true)}
                                    style={{
                                        fontSize: '16px',
                                    }}
                                />
                            )}
                        </div>
                        <DetailsContainer>
                            <LineInput
                                label={Translate.t('Profile.ProfileFirstName')}
                                value={stateFirstName}
                                onChangeText={(e) => setStateFirstName(e.target.value)}
                                disabled={!isEditing}
                            />

                            <LineInput
                                label={Translate.t('Profile.ProfileLastName')}
                                value={stateLastName}
                                onChangeText={(e) => setStateLastName(e.target.value)}
                                disabled={!isEditing}
                            />

                            <LineInput
                                label={Translate.t('Profile.ProfilePhoneNumber')}
                                value={statePhoneNumber}
                                onChangeText={(e) => setStatePhoneNumber(e.target.value)}
                                disabled={!isEditing}
                            />

                            <LineInput
                                label={Translate.t('Profile.ProfileRenNumber')}
                                value={stateRenNumber}
                                onChangeText={(e) => setStateRenNumber(e.target.value)}
                                disabled={!isEditing}
                            />

                            <LineInput
                                label={Translate.t('Profile.ProfileCompanyName')}
                                value={stateCompanyName}
                                onChangeText={(e) => setStateCompanyName(e.target.value)}
                                disabled={!isEditing}
                            />

                            <LineInput
                                label={Translate.t('Profile.ProfileAddress1')}
                                value={stateAddress1}
                                onChangeText={(e) => setStateAddress1(e.target.value)}
                                disabled={!isEditing}
                            />

                            <LineInput
                                label={Translate.t('Profile.ProfileAddress2')}
                                value={stateAddress2}
                                onChangeText={(e) => setStateAddress2(e.target.value)}
                                disabled={!isEditing}
                            />

                            <LineInput
                                label={Translate.t('Profile.ProfilePostcode')}
                                value={statePostcode}
                                onChangeText={(e) => setStatePostcode(e.target.value)}
                                disabled={!isEditing}
                            />

                            <LineInput
                                label={Translate.t('Profile.ProfileCity')}
                                value={stateCity}
                                onChangeText={(e) => setStateCity(e.target.value)}
                                disabled={!isEditing}
                            />

                            <LineInput
                                label={Translate.t('Profile.ProfileState')}
                                value={stateState}
                                onChangeText={(e) => setStateState(e.target.value)}
                                disabled={!isEditing}
                            />

                            <SelectionInput
                                label={Translate.t('Profile.ProfileCountry')}
                                data={CountriesOptions}
                                currentValue={stateCountry}
                                onChangeSelection={(e) => setStateCountry(e.target.value)}
                                disabled={!isEditing}
                            />
                        </DetailsContainer>
                    </InputsContainer>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'center',
                        }}
                    >
                        {updateUserInfoError && (
                            <div
                                style={{
                                    marginRight: '30px',
                                    color: 'red',
                                }}
                            >
                                {updateUserInfoError}
                            </div>
                        )}

                        {renderActionButton()}
                    </div>
                </Container>

                {renderLoadingOverlay()}
                {renderRenDocumentModal()}

                <RENDocumentModal />
            </MainContainer>
        </>
    );
};

const SectionTitle = styled.h3`
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
`;

const DetailsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(210px, 1fr) );
    row-gap: 8px;
    column-gap: 56px;
    width: 100%;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 824px;
    padding: 40px;
    color: #000;
`;

const InputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 16px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
`;

const mapStateToProps = (state: RootState) => ({
    userInfo: Selectors.userGetUserInfo(state),

    updateUserInfoLoading: Selectors.userUpdateUserInfoAttempting(state),
    updateUserInfoError: Selectors.userUpdateUserInfoError(state),

    isUploadRENDocModalOpen: Selectors.userGetIsRENUploadModalOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    updateUserInfo: (data: IUpdateREAUserInfo) => dispatch(Actions.updateUserInfoAttempt(data)),
    setUploadRENModalOpen: (state: boolean) => dispatch(Actions.setRENUploadModalOpen(state)),
    setCurrentPathName: (pathname: string) => dispatch(Actions.setCurrentPathName(pathname)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
