import { ApiResponse } from 'apisauce';
import { REAGetOfferResponse, REAGetPropertiesResponseWithIndex, REAGetPropertyDetailsResponse } from '@dm/types';
import { IPropertyGateway, GetPropertyListingParams, GetPropertyDetailsParams, GetCoBrokeUploadUrlParams, UploadCoBrokeDocumentParams, GetCoBrokeDocumentTemplateParams, GetViewOfferSessionsParams, setSubmitOffersParams, requestKeyDateParams } from './PropertyBase';
import { GatewayResponse } from './types/types';

const getHeaders = (authToken: string) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
});

export default class PropertyGateway extends IPropertyGateway {
    async getPropertyListing(params: GetPropertyListingParams): GatewayResponse<REAGetPropertiesResponseWithIndex> {
        const { authToken, ...rest } = params;
        const response: ApiResponse<REAGetPropertiesResponseWithIndex> = await this.api.get('rea/search/properties', { ...rest }, getHeaders(authToken));
        return this.process(response);
    }

    async getPropertyDetails(params: GetPropertyDetailsParams): GatewayResponse<REAGetPropertyDetailsResponse> {
        const { authToken, id } = params;
        const response: ApiResponse<REAGetPropertyDetailsResponse> = await this.api.get('rea/search/propertyDetails', { id }, getHeaders(authToken));
        return this.process(response);
    }

    async getCoBrokeUploadUrl(params: GetCoBrokeUploadUrlParams): GatewayResponse<string> {
        const { category, id, name, extension } = params;
        const response: ApiResponse<string> = await this.api.get('/upload/url', { category, id, name, extension }, getHeaders(params.authToken));
        return this.process(response);
    }

    async uploadCoBrokeDocument(params: UploadCoBrokeDocumentParams): GatewayResponse<null> {
        const response: ApiResponse<null> = await this.api.put(params.url, params.doc);
        return this.process(response);
    }

    async getCoBrokeDocumentTemplate(params: GetCoBrokeDocumentTemplateParams): GatewayResponse<string> {
        const { documentTemplate, propertyId } = params;
        const response: ApiResponse<string> = await this.api.get('/rea/document/cobroke', { documentTemplate, propertyId }, getHeaders(params.authToken));
        return this.process(response);
    }

    async getPropertyViewOfferSessions(params: GetViewOfferSessionsParams): GatewayResponse<REAGetOfferResponse[] | null> {
        const { propertyId } = params;
        const response: ApiResponse<REAGetOfferResponse[] | null> = await this.api.get(`/rea/offer/viewOfferSessions?propertyId=${propertyId}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async setPropertySubmitOffers(params: setSubmitOffersParams): GatewayResponse<string> {
        const { offerSessionId, price } = params;
        const response: ApiResponse<string> = await this.api.post('/rea/offer/submitOffer', { offerSessionId, price }, getHeaders(params.authToken));
        return this.process(response);
    }

    async setRequestPropertyKey(params: requestKeyDateParams): GatewayResponse<string> {
        const { propertyId, requestKeyDate } = params;
        const response: ApiResponse<string> = await this.api.post('/rea/property/requestKey', { propertyId, requestKeyDate }, getHeaders(params.authToken));
        return this.process(response);
    }
}
