import React, { FunctionComponent, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import dayjs from 'dayjs';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BreadCrumb, Button, Colors, IImageCarousel, ImageCarousel, LineInput } from '@dm/bigfish';

import {
    FurnishingTypeEnum,
    LandTitleTypeEnum,
    PropertyStatus,
    PropertyTitleTypeEnum,
    PropertyTypeEnum,
    RaceEnum,
    REAGetOfferResponse,
    REAGetPropertyDetailsResponse,
    TenureTypeEnum,
} from '@dm/types';
import PageSkeleton from 'components/Skeleton';
import { toast } from 'react-toastify';
import { FurnishingTypeOptions, LandTitleOptions, PropertyStatusOptions, PropertyTitleOptions, PropertyTypeOptions, TenureTypeOptions } from 'components/options/Property';
import { Oval } from 'react-loader-spinner';
import Translate from 'lib/translate';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import NavActions from 'lib/NavActions';

import MainContainer from 'components/MainContainer';
import SelectionInput from 'components/SelectionInput';
import icons from 'assets/icons';
import Modal from 'components/Modal';
import Map from './components/Map';

interface PropertyDetailsProps {
    getPropertyDetailsLoading: boolean;
    getPropertyDetailsError: string;
    propertyDetails: REAGetPropertyDetailsResponse;

    viewOfferSessionsLoading: boolean;
    viewOfferSessionsError: string;
    viewOfferSessionsData: REAGetOfferResponse[] | null;

    setPropertyKeyDateLoading: boolean,
    setPropertyKeyDateError: string,
    setPropertyKeyDateSuccess: string,

    getSubmitBidLoading: boolean;

    getViewOfferSessions:(propertyId: string) => void;
    getPropertyDetails: (propertyId: string) => void;
    setPropertySubmitOffer: (offerSessionId: string, price: number) => void;
    clearPropertyViewOfferSessions: () => void;
    setPropertyRequestKey:(propertyId: string, requestKeyDate: string) => void
}

const PropertyDetails: FunctionComponent<PropertyDetailsProps> = (props: PropertyDetailsProps) => {
    const { getPropertyDetailsLoading,
        getPropertyDetailsError,
        propertyDetails,
        viewOfferSessionsError,
        viewOfferSessionsLoading,
        viewOfferSessionsData,
        setPropertyKeyDateLoading,
        setPropertyKeyDateError,
        setPropertyKeyDateSuccess,
        getSubmitBidLoading,
        getViewOfferSessions,
        getPropertyDetails,
        setPropertySubmitOffer,
        clearPropertyViewOfferSessions,
        setPropertyRequestKey } = props;

    const [dateFrom, setDateFrom] = useState(dayjs(new Date()).add(1, 'd').toDate());
    const [minDate, setMinDate] = useState(dayjs(new Date()).add(1, 'd').toDate());
    const [maxDate, setMaxDate] = useState(dayjs(new Date()).add(60, 'd').toDate());
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('BreadCrumb.BreadCrumbDashboard'),
                onClick: () => { return false; },
            },
            {
                label: Translate.t('Search.SearchBcSearch'),
                onClick: () => { return false; },
            },
            {
                label: Translate.t('Search.SearchBcPropertiesResult'),
                onClick: () => { return false; },
            },
        ],
    );

    const [statePropertyName, setStatePropertyName] = useState('');
    const [statePropertyDesc, setStatePropertyDesc] = useState('');
    const [statePropertyStatus, setStatePropertyStatus] = useState<PropertyStatus>(PropertyStatus.pendingApproval);
    const [imageCarouselData, setImageCarouselData] = useState<IImageCarousel[]>([]);

    // Details Primitive
    const [stateBuildUpPricePerSqft, setStateBuildUpPricePerSqft] = useState(0);
    const [stateBuildUpSize, setStateBuildUpSize] = useState(0);
    const [stateBuildUpDimension, setStateBuildUpDimension] = useState('');
    const [stateFacingDirection, setStateFacingDirection] = useState('');
    const [stateOccupancy, setStateOccupancy] = useState('');
    const [stateUnitType, setStateUnitType] = useState('');
    const [stateStorey, setStateStorey] = useState(0);

    // Details with Enums and Entities
    const [stateType, setStateType] = useState<PropertyTypeEnum>(PropertyTypeEnum.Apartment);
    const [stateTitleType, setStateTitleType] = useState<PropertyTitleTypeEnum>(PropertyTitleTypeEnum.Individual);
    const [stateLandTitle, setStateLandTitle] = useState<LandTitleTypeEnum>(LandTitleTypeEnum.Commercial);
    const [stateTenure, setStateTenure] = useState<TenureTypeEnum>(TenureTypeEnum.FreeHold);
    const [stateFurnishing, setStateFurnishing] = useState<FurnishingTypeEnum>(FurnishingTypeEnum.None);
    const [statePrefferedTenant, setStatePrefferedTenant] = useState<RaceEnum[]>([]);

    // Proper Offer
    const [offer, setOffer] = useState(0);
    const [offerText, setOfferText] = useState('');

    const params = useParams();
    const { id = '' } = params;

    useEffect(() => {
        clearPropertyViewOfferSessions();
        getPropertyDetails(id);
    }, [id]);

    const setDateRequestModalOpen = () => {
        setIsModalOpen(!isModalOpen);
    };

    const setDateRequestKey = () => {
        setPropertyRequestKey(id, dayjs(dateFrom).format('YYYY-MM-DD'));
        setIsModalOpen(!isModalOpen);
    };

    const renderSelectDateModal = () => {
        return (
            <Modal
                show={isModalOpen}
                width='20%'
                closeCursor
                onClickClose={() => setIsModalOpen(false)}
            >
                <ModalPaddingContainer>
                    <TitleContainer>
                        {Translate.t('PropertyRpDetails.PropertyRpDetailsSelectdate')}
                    </TitleContainer>
                    <DateContainer>
                        <StyledDatePicker
                            value={dayjs(dateFrom).format('DD/MM/YYYY')}
                            onChange={(e: Date) => setDateFrom(e)}
                            minDate={minDate}
                            maxDate={maxDate}
                        />
                        <ButtonSetDateContainer
                            disabled={setPropertyKeyDateLoading}
                            label={Translate.t('PropertyRpDetails.PropertyRpDetailsButtonLabelSetDate')}
                            onClick={setDateRequestKey}
                        />
                    </DateContainer>

                </ModalPaddingContainer>
            </Modal>
        );
    };

    useEffect(() => {
        const { name, description, status, images, details } = propertyDetails;

        const {
            buildUpPricePerSqft,
            buildUpSize,
            builtUpDimension,
            facingDirection,
            furnishing,
            landTitle,
            occupancy,
            preferredTenant,
            storey,
            tenure,
            titleType,
            type,
            unitType,
        } = details;

        setStatePropertyName(name);
        setStatePropertyDesc(description);
        setStatePropertyStatus(status);

        setStateBuildUpPricePerSqft(buildUpPricePerSqft);
        setStateBuildUpSize(buildUpSize);
        setStateBuildUpDimension(builtUpDimension);
        setStateFacingDirection(facingDirection);
        setStateOccupancy(occupancy);
        setStateUnitType(unitType);
        setStateStorey(storey);

        setStateType(type);
        setStateTitleType(titleType);
        setStateLandTitle(landTitle);
        setStateTenure(tenure);
        setStateFurnishing(furnishing);
        setStatePrefferedTenant(preferredTenant);

        if (images.length) {
            const parsedPropertyDetailsImages: IImageCarousel[] = [];

            images.forEach((image) => {
                const { url, name: ImageName } = image;

                url.forEach((imageUrl, urlIndex) => {
                    parsedPropertyDetailsImages.push({
                        image: imageUrl,
                        desc: `${ImageName} ${urlIndex + 1}`,
                    });
                });
            });

            setImageCarouselData(parsedPropertyDetailsImages);
        }
    }, [propertyDetails]);

    useEffect(() => {
        if (viewOfferSessionsData?.length === 0) {
            setOfferText(Translate.t('PropertyRpDetails.PropertyRpOfferNoSession'));
            toast.error(Translate.t('PropertyRpDetails.PropertyRpOfferNoSession'));
        } else {
            setOfferText('');
        }
    }, [viewOfferSessionsData]);

    if (getPropertyDetailsLoading) {
        return (
            <>
                <BreadCrumb onBackPressed={() => NavActions.navBack()} data={breadCrumb} />
                <div style={{ backgroundColor: 'white' }}>
                    <PageSkeleton row={10} />
                </div>
            </>
        );
    }

    if (getPropertyDetailsError) {
        return (
            <>
                <BreadCrumb onBackPressed={() => NavActions.navBack()} data={breadCrumb} />
                <MainContainer>
                    <LoadingContainer>
                        <h1
                            style={{
                                color: Colors.danger,
                            }}
                        >
                            {getPropertyDetailsError}
                        </h1>
                    </LoadingContainer>
                </MainContainer>
            </>
        );
    }

    const getViewOfferSessionsFunction = () => {
        getViewOfferSessions(propertyDetails.id);
    };

    const setSubmitOfferSessionsFunction = (e: React.FormEvent) => {
        e.preventDefault();
        if (Number(offer) === 0) {
            toast.error(Translate.t('PropertyRpDetails.PropertyRpPriceNot0'));
        } else if ((!viewOfferSessionsLoading) && (viewOfferSessionsData?.length && (viewOfferSessionsData[viewOfferSessionsData.length - 1].endDate))) {
            const offerSesionId = viewOfferSessionsData[viewOfferSessionsData.length - 1]._id;
            (
                setPropertySubmitOffer(offerSesionId, offer)
            );
        }
    };

    const inputHandler = (e: any) => {
        const newValue = e.target.value;
        setOffer(newValue);
    };

    return (
        <>
            <BreadCrumb onBackPressed={() => NavActions.navBack()} data={breadCrumb} />
            <MainContainer>
                <ImageCarouselContainer>
                    {
                        imageCarouselData.length >= 1 && (
                            <ImageCarousel
                                data={imageCarouselData}
                            />

                        )
                    }
                </ImageCarouselContainer>

                <DetailsViewContainer>
                    <DetailsInfoContainer>
                        <BasicDetailsContainer>
                            <Title>
                                {statePropertyName}
                            </Title>
                            <Text>
                                {statePropertyDesc}
                            </Text>
                        </BasicDetailsContainer>

                        <PropertyDetailsContainer>
                            <Title>
                                {Translate.t('PropertyRpDetails.PropertyRpDetailsTitlePropDetails')}
                            </Title>
                            <PropertyDetailsInfoContainer>
                                <SelectionInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelPropType')}
                                    data={PropertyTypeOptions}
                                    currentValue={stateType}
                                    onChangeSelection={(e) => setStateType(+e.target.value)}
                                    disabled
                                />
                                <SelectionInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelPropStatus')}
                                    data={PropertyStatusOptions}
                                    currentValue={statePropertyStatus}
                                    onChangeSelection={(e) => setStatePropertyStatus(+e.target.value)}
                                    disabled
                                />
                                <SelectionInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelLandTitle')}
                                    data={LandTitleOptions}
                                    currentValue={stateLandTitle}
                                    onChangeSelection={(e) => setStateLandTitle(+e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelUnitType')}
                                    value={stateUnitType}
                                    onChangeText={(e) => setStateUnitType(e.target.value)}
                                    disabled
                                />
                                <SelectionInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelPropTitleType')}
                                    data={PropertyTitleOptions}
                                    currentValue={stateTitleType}
                                    onChangeSelection={(e) => setStateTitleType(+e.target.value)}
                                    disabled
                                />
                                <SelectionInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelTenure')}
                                    data={TenureTypeOptions}
                                    currentValue={stateTenure}
                                    onChangeSelection={(e) => setStateTenure(+e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelStorey')}
                                    value={String(stateStorey)}
                                    onChangeText={(e) => setStateStorey(e.target.valueAsNumber)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelBtSize')}
                                    value={String(stateBuildUpSize)}
                                    onChangeText={(e) => setStateBuildUpSize(e.target.valueAsNumber)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelBtPrice')}
                                    value={String(stateBuildUpPricePerSqft)}
                                    onChangeText={(e) => setStateBuildUpPricePerSqft(e.target.valueAsNumber)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelBtDim')}
                                    value={stateBuildUpDimension}
                                    onChangeText={(e) => setStateBuildUpDimension(e.target.value)}
                                    disabled
                                />
                                <SelectionInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelFurnishing')}
                                    data={FurnishingTypeOptions}
                                    currentValue={stateFurnishing}
                                    onChangeSelection={(e) => setStateFurnishing(+e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelOccupancy')}
                                    value={stateOccupancy}
                                    onChangeText={(e) => setStateOccupancy(e.target.value)}
                                    disabled
                                />
                                <LineInput
                                    label={Translate.t('PropertyRpDetails.PropertyRpDetailsLabelFacingDirection')}
                                    value={stateFacingDirection}
                                    onChangeText={(e) => setStateFacingDirection(e.target.value)}
                                    disabled
                                />
                            </PropertyDetailsInfoContainer>
                        </PropertyDetailsContainer>
                        <RoomDetailsContainer>
                            <Title>{Translate.t('PropertyRpDetails.PropertyRpDetailsTitleRoomDetails')}</Title>
                            <PropertyImageGalleryContainer>
                                {
                                    propertyDetails?.images && propertyDetails?.images.map((roomDetail, roomDetailIndex) => {
                                        const roomDetailKey = roomDetailIndex + roomDetail.name;

                                        return (
                                            propertyDetails?.images.length ? (
                                                <PropertyImageGalleryCard key={roomDetailKey}>
                                                    <PropertyImageGalleryTitle>{roomDetail.name}</PropertyImageGalleryTitle>
                                                    <PropertyImageGallery>
                                                        {
                                                            roomDetail.url.map((roomImage, roomImageIndex) => {
                                                                const roomImageKey = roomImageIndex + roomImage;
                                                                return (
                                                                    <img src={roomImage} alt='room images' key={roomImageKey} />
                                                                );
                                                            })
                                                        }
                                                    </PropertyImageGallery>
                                                    {propertyDetails?.images.length > 1 && <Text style={{ fontSize: '11px', lineHeight: 1, fontStyle: 'italic', color: Colors.greyDark, marginTop: '10px' }}>{Translate.t('PropertyRpDetails.PropertyRpDetailsInstruction')}</Text>}
                                                </PropertyImageGalleryCard>
                                            ) : (
                                                Translate.t('PropertyRpDetails.PropertyRpDetailsErrorNoImagesFound')
                                            ));
                                    })
                                }

                                { !propertyDetails?.images.length && Translate.t('PropertyRpDetails.PropertyRpDetailsErrorNoImagesFound')}
                            </PropertyImageGalleryContainer>
                        </RoomDetailsContainer>
                    </DetailsInfoContainer>

                    <OfferContainer>
                        <Text
                            style={{
                                fontSize: '18px',
                                fontWeight: 'bold',
                            }}
                        >
                            {Translate.t('PropertyRpDetails.PropertyRpDetailsOfferText')}
                        </Text>

                        <Button
                            label={Translate.t('PropertyRpDetails.PropertyRpDetailsButtonLabelOffer')}
                            style={{
                                width: '30%',
                            }}
                            onClick={getViewOfferSessionsFunction}
                        />

                        {(viewOfferSessionsLoading && !viewOfferSessionsError) && (
                            <div style={{ backgroundColor: 'white' }}>
                                <PageSkeleton row={1} />
                            </div>
                        )}

                        {(!viewOfferSessionsLoading && (viewOfferSessionsData?.length && viewOfferSessionsData?.length !== 0) && (viewOfferSessionsData[viewOfferSessionsData.length - 1].endDate)) ? (

                            <div style={{ display: 'flex' }}>
                                <OfferDisplayContainer>
                                    <p>{Translate.t('PropertyRpDetails.PropertyRpSetOfferPrice')}</p>
                                    <InputContainer placeholder=' 123 ' value={String(offer)} onChange={e => inputHandler(e)} />
                                </OfferDisplayContainer>

                                <ButtonCircle onClick={setSubmitOfferSessionsFunction}>
                                    {!getSubmitBidLoading && (
                                        <UploadIcon
                                            src={icons.PropertyBid}
                                        />
                                    )}
                                    {getSubmitBidLoading && (
                                        <LoaderContainer>
                                            <Oval
                                                height={20}
                                                width={20}
                                                color={Colors.secondary}
                                                secondaryColor={Colors.white}
                                            />
                                        </LoaderContainer>

                                    )}

                                </ButtonCircle>

                            </div>
                        ) : <WarningNoOfferContainer>{offerText}</WarningNoOfferContainer>}

                        <Button
                            label={Translate.t('PropertyRpDetails.PropertyRpDetailsButtonLabelRequestKey')}
                            style={{
                                width: '30%',
                            }}
                            onClick={setDateRequestModalOpen}
                        />

                        {renderSelectDateModal()}
                        {propertyDetails?.location?.address1.length ? (
                            <Map propertyInfo={propertyDetails} />
                        ) : (
                            <div>
                                {Translate.t('PropertyRpDetails.PropertyRpNoLocation')}
                            </div>
                        )}
                    </OfferContainer>
                </DetailsViewContainer>
            </MainContainer>
        </>
    );
};

const InputContainer = styled.input`
    width: 85%;
    height: 40px;
    margin-bottom: 16px;
    padding: 20px;
    background-color:#F6F6F6;
    font-family: Helvetica;
    color: black;
    font-size: 12px;
    line-height: 14px;
    border: none;
    border-radius: 12px;
    transition: all .5s;
    &:focus {
      outline-color: #cccccc;
    }
    justify-content: center;
    align-items: center;
    &::placeholder {
      display: flex;
      margin: 0px;
      padding:0px;
      color: #696969;
    }
    overflow-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
    margin-top: 2px;
`;

const OfferDisplayContainer = styled.div`
    display: flex;
    flex-direction: column;
    
`;

const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
`;

const WarningNoOfferContainer = styled.div`
    color: red ;
`;

const ButtonCircle = styled.button`
    width: 12%;
    height: 40px;
    border-radius: 20px;
    background-color: orange;
    justify-content: center;
    align-items: center;
    align-self: center;
    border: none;
    margin-top: 2px;
`;

const UploadIcon = styled(SVG)`
`;

const ModalPaddingContainer = styled.div`
    padding: 20px;
`;

const TitleContainer = styled.h4`
    margin-bottom: 30px;
`;

const ButtonSetDateContainer = styled(Button)`
    width: 70%;
    max-height: 30px;
    padding: 8px;
    line-height: 15px;
    font-size: 14px;    
`;

const StyledDatePicker = styled(ReactDatePicker)`
    width: 50%;
    background: none;
    color: #696969;
    font-size: 14px;
    text-transform: capitalize;
    text-align: center;
    box-sizing: border-box;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #a2a2a2;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    .react-datepicker__day {
        cursor: default;
        color: #ccc;
    } 
    .react-datepicker__month-text--disabled, 
    .react-datepicker__quarter-text--disabled, 
    .react-datepicker__year-text--disabled {
        cursor: default;
        color: #ccc;
    }
`;
const OfferContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    margin-bottom: 40px;
    height: fit-content;
    gap: 20px;
    width: 40%;
`;

const LoadingContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ImageCarouselContainer = styled.div`
    width: 100%;
    margin-top: 20px;
`;

const DateContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const DetailsViewContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px;
`;

const Title = styled.h3`
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 12px;
    text-transform: capitalize;
`;

const Text = styled.p`
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    overflow-wrap: break-word;
    word-wrap: break-word;
`;

const DetailsInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-right: 15px;
    > div {
        padding: 20px;
        box-sizing: border-box;
        box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        margin-bottom: 40px;
    }
`;

const BasicDetailsContainer = styled.div`
    display: block;
    width: 100%;
`;

const PropertyDetailsContainer = styled.div`
    display: block;
    width: 100%;
`;

const PropertyDetailsInfoContainer = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(210px, 1fr) );
    row-gap: 8px;
    column-gap: 56px;
    width: 100%;
    align-items: flex-end;
`;

const RoomDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const PropertyImageGalleryContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
`;

const PropertyImageGalleryCard = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const PropertyImageGalleryTitle = styled.h5`
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 24px;
`;

const PropertyImageGallery = styled.div`
    display: flex;
    width: 100%;
    height: 340px;
    overflow-x: auto;
    img {
        display: inline-block;
        width: 90%;
        height: 100%;
        object-fit: cover;
    }
`;

const mapStateToProps = (state: RootState) => ({
    getPropertyDetailsLoading: Selectors.propertyGetPropertyDetailsAttempting(state),
    getPropertyDetailsError: Selectors.propertyGetPropertyDetailsError(state),
    propertyDetails: Selectors.propertyGetPropertyDetails(state),

    viewOfferSessionsLoading: Selectors.propertyGetPropertyViewOfferSessionsAttemptingAttempting(state),
    viewOfferSessionsError: Selectors.propertyGetPropertyViewOfferSessionsErrorError(state),
    viewOfferSessionsData: Selectors.propertyGetPropertyViewOfferSessions(state),

    setPropertyKeyDateLoading: Selectors.propertySetRequestKeyAttempt(state),
    setPropertyKeyDateError: Selectors.propertySetRequestKeyFailure(state),
    setPropertyKeyDateSuccess: Selectors.propertySetRequestKeySuccess(state),

    getSubmitBidLoading: Selectors.propertySetPropertySetPropertySubmitOfferAttemptinggAttempting(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getPropertyDetails: (propertyId: string) => dispatch(Actions.getPropertyDetailsAttempt(propertyId)),
    getViewOfferSessions: (propertyId: string) => dispatch(Actions.getPropertyViewOfferSessionsAttempt({ propertyId })),
    setPropertySubmitOffer: (offerSessionId: string, price: number) => dispatch(Actions.setPropertySubmitOfferAttempt({ offerSessionId, price })),
    clearPropertyViewOfferSessions: () => dispatch(Actions.clearPropertyViewOfferSessions()),
    setPropertyRequestKey: (propertyId: string, requestKeyDate: string) => dispatch(Actions.setRequestKeyAttempt({ propertyId, requestKeyDate })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetails);
