import React, { FunctionComponent, useState } from 'react';
import { BreadCrumb, Button } from '@dm/bigfish';
import SVG from 'react-inlinesvg';

import Translate from 'lib/translate';
import NavActions from 'lib/NavActions';

import icons from 'assets/icons';

import MainContainer from 'components/MainContainer';
import styled from 'styled-components';

const ErrorScreen: FunctionComponent = () => {
    const [breadCrumb] = useState(
        [
            {
                label: Translate.t('Error.ErrorGeneralErrorScreenBc'),
                onClick: () => { return false; },
            },
        ],
    );

    return (
        <>
            <BreadCrumb onBackPressed={() => NavActions.navBack()} data={breadCrumb} />
            <MainContainer>
                <InnerContainer>
                    <SVG
                        src={icons.TriangleIcon}
                        style={{
                            height: '10%',
                            width: '10%',
                        }}
                    />

                    <TextAndButtonContainer>

                        <h1>
                            {Translate.t('Error.ErrorGeneralErrorScreenText')}
                        </h1>

                        <Button
                            label='Back'
                            onClick={() => NavActions.navBack()}
                            style={{
                                width: '30%',
                            }}
                        />
                    </TextAndButtonContainer>
                </InnerContainer>
            </MainContainer>
        </>
    );
};

const InnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 750px;
    gap: 40px;
`;

const TextAndButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
`;

export default ErrorScreen;
