import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import dayjs from 'dayjs';
import Translate from 'lib/translate';
import PropertyGateway from 'api/Property';
import { GatewayResponseStatus } from 'api/types/types';
import { GetViewOfferSessionsActionPayload } from 'redux/slices/property/types';
import { toast } from 'react-toastify';

export default function* watchGetPropertyViewOfferSessions(api: PropertyGateway): SagaWatcherReturnType {
    yield takeEvery('property/getPropertyViewOfferSessionsAttempt', handleGetViewOfferSessions, api);
}

function* handleGetViewOfferSessions(api: PropertyGateway, data: GetViewOfferSessionsActionPayload) {
    const authToken = yield* select(Selectors.authGetAuthToken);

    const { propertyId } = data.payload;

    const response = yield* call([api, api.getPropertyViewOfferSessions], { authToken, propertyId });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getPropertyViewOfferSessionsFailure(response.message));
        toast.error(Translate.t('PropertyRpDetails.PropertyRpOfferNoSession'));
        return;
    }

    yield put(Actions.getPropertyViewOfferSessionsSuccess(response.data));

    if (response.data?.length !== 0) {
        const { startDate, endDate } = response.data![response.data!.length - 1];
        toast.success(`${Translate.t('PropertyRpDetails.PropertyRpSuccesSession')} ${dayjs(startDate).format('YYYY-MM-DD')} ${Translate.t('PropertyRpDetails.PropertyRpSuccesSessionEnd')} ${dayjs(endDate).format('YYYY-MM-DD')}`);
    }
}
