import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    LoginActionPayload,
    AuthReduxState,
    SignupActionPayload,
    VerifySignUpPayload,
    UnsubscribePayload,
} from './types';

const initialState: AuthReduxState = {
    actions: {
        login: false,
        startup: true,
        signup: false,
        verifySignUp: false,
        unsubscribe: false,
    },
    authToken: '',
    verifySignUp: false,
    error: {
        login: '',
        startup: '',
        signup: '',
        verifySignUp: '',
        unsubscribe: '',
    },
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authStartupAttempt: (state) => {
            state.actions.startup = true;
            state.error.startup = '';
        },
        authStartupSuccess: (state) => {
            state.actions.startup = false;
        },
        authStartupFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.startup = false;
            if (action.payload) {
                state.error.startup = action.payload;
            }
        },
        authLoginAttempt: (state, _action: LoginActionPayload) => {
            state.actions.login = true;
            state.error.login = '';
        },
        authLoginSuccess: (state, action: PayloadAction<string>) => {
            state.actions.login = false;
            state.authToken = action.payload;
        },
        authLoginFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.login = false;
            if (action.payload) {
                state.error.login = action.payload;
            }
        },

        authLogout: (state) => {
            state.actions.login = false;
            state.authToken = '';
        },

        authSignUpAttempt: (state, _action: SignupActionPayload) => {
            state.actions.signup = true;
            state.error.signup = '';
        },
        authSignUpSuccess: (state) => {
            state.actions.signup = false;
            state.error.signup = '';
        },
        authSignUpFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.signup = false;
            if (action.payload) {
                state.error.signup = action.payload;
            }
        },
        authVerifySignUpAttempt: (state, _action: VerifySignUpPayload) => {
            state.actions.verifySignUp = true;
            state.error.verifySignUp = '';
        },
        authVerifySignUpSuccess: (state, action: PayloadAction<boolean>) => {
            state.actions.verifySignUp = false;
            state.verifySignUp = action.payload;
        },
        authVerifySignUpFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.verifySignUp = false;
            state.verifySignUp = false;
            if (action.payload) {
                state.error.verifySignUp = action.payload;
            }
        },
        clearVerifySignUp: (state) => {
            state.verifySignUp = false;
        },
        authUnsubscribeAttempt: (state, _action: UnsubscribePayload) => {
            state.actions.unsubscribe = true;
            state.error.unsubscribe = '';
        },
        authUnsubscribeSuccess: (state) => {
            state.actions.unsubscribe = false;
        },
        authUnsubscribeFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.unsubscribe = false;
            if (action.payload) {
                state.error.unsubscribe = action.payload;
            }
        },
    },
});

export type AuthState = typeof initialState;

export default {
    actions: authSlice.actions,
    reducers: authSlice.reducer,
};
