import config from 'config';

import AuthGateway from './Auth';
import UserGateway from './User';
import PropertyGateway from './Property';

const baseUrl = config.baseUrl as string;

const auth = new AuthGateway(baseUrl);
const user = new UserGateway(baseUrl);
const property = new PropertyGateway(baseUrl);

export default {
    auth,
    user,
    property,
};
