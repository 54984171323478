import { fork } from 'redux-saga/effects';
import PropertyGateway from 'api/Property';

import { RootSagaReturnType } from 'sagas/types';

import watchGetPropertyListing from './getPropertyListing';
import watchGetPropertyDetails from './getPropertyDetails';
import watchGetCoBrokeUploadUrl from './getCoBrokeUploadUrl';
import watchUploadCoBrokeDocument from './uploadCoBrokeDocument';
import watchGetCoBrokeTemplate from './getPropertyCoBrokeTemplate';
import watchGetPropertyViewOfferSessions from './getPropertyViewOfferSessions';
import watchSetPropertySubmitOfferAttempt from './setPropertySubmitOffers';
import watchSetPropertyRequestKey from './setPropertyRequestKey';

export default (api: PropertyGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetPropertyListing, api);
        yield fork(watchGetPropertyDetails, api);
        yield fork(watchGetCoBrokeUploadUrl, api);
        yield fork(watchUploadCoBrokeDocument, api);
        yield fork(watchGetCoBrokeTemplate, api);
        yield fork(watchGetPropertyViewOfferSessions, api);
        yield fork(watchSetPropertySubmitOfferAttempt, api);
        yield fork(watchSetPropertyRequestKey, api);
    }

    return {
        rootSaga,
    };
};
